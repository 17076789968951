import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import UserContext from '../../../UserContext';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { useFormik } from 'formik';
import { StyledA, AuthenticationTitle } from "@queensland-running/qr-components";

const SignIn = ({ history, updateErrorMessage, switchState }: RouteComponentProps & { updateErrorMessage: any, switchState: (state: string) => void }) => {
  const { updateCurrentUser } = useContext(UserContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [user, setUser] = useState(false);

  const signInForm = useFormik({
    initialValues: {
      emailAddress: '',
      password: '',
    },
    onSubmit: (values) => {
      Auth.signIn(values.emailAddress, values.password)
        .then((tempUser) => {
          if (tempUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            setShowConfirmation(true);
            setUser(tempUser);
          }
          if (!tempUser.signInUserSession) {
            setShowConfirmation(true);
            setUser(tempUser);
          } else {
            updateCurrentUser(tempUser);
            history.push('/');
          }
        })
        .catch((err) => {
          updateErrorMessage(err.message);
        });
    },
  });

  const confirmationForm = useFormik({
    initialValues: {
      password: '',
      //@ts-ignore
      givenName: user?.challengeParam?.userAttributes['given_name'] || '',
      //@ts-ignore
      familyName: user?.challengeParam?.userAttributes['family_name'] || '',
    },
    onSubmit: (values) => {
      Auth.completeNewPassword(user, values.password, {
        given_name: values.givenName,
        family_name: values.familyName,
      })
        .then(() => {
          history.push('/');
        })
        .catch((err) => {
          console.log('error confirming signing in...: ', err);
          updateErrorMessage(err.message);
        });
    },
  });

  //@ts-ignore
  const requiredAttributes = user?.challengeParam?.requiredAttributes || [];

  return (
    <>
      {!showConfirmation && (
        <form onSubmit={signInForm.handleSubmit}>
          <AuthenticationTitle title='Sign in to your account'/>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                label="Email Address"
                name="emailAddress"
                onChange={signInForm.handleChange}
                value={signInForm.values.emailAddress}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="password"
                label="Password"
                type="password"
                onChange={signInForm.handleChange}
                value={signInForm.values.password}
                fullWidth
              />
              <StyledA style={{ float: 'right' }} onClick={() => switchState('showForgotPassword')}>Forgot your password?</StyledA>
            </Grid>
            <Grid container item xs={12} justify="space-between" direction="row-reverse">
              <Button type="submit" color="primary" variant="contained" style={{ float: 'right'}}>
                Sign in
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {showConfirmation && (
        <form onSubmit={confirmationForm.handleSubmit}>
          <AuthenticationTitle title='Account activation' />
          {requiredAttributes.length > 0 && (
            <Grid item xs={12}>
              <Typography>We need a few additional details before you can continue.</Typography>
            </Grid>
          )}
          <Grid container spacing={3}>
            {//@ts-ignore
            requiredAttributes.includes('given_name') && (
              <Grid item xs={12}>
                <TextField
                  required
                  label="First name"
                  name="givenName"
                  onChange={confirmationForm.handleChange}
                  value={confirmationForm.values.givenName}
                  fullWidth
                />
              </Grid>
            )}
            {//@ts-ignore
            requiredAttributes.includes('family_name') && (
              <Grid item xs={12}>
                <TextField
                  required
                  label="Last name"
                  name="familyName"
                  onChange={confirmationForm.handleChange}
                  value={confirmationForm.values.familyName}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                required
                label="New Password"
                name="password"
                type="password"
                onChange={confirmationForm.handleChange}
                value={confirmationForm.values.password}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" color="primary" variant="contained" fullWidth>
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default SignIn;
