import React, {ReactNode, useState} from 'react';
import { compose } from 'recompose';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useFetchRunnersQuery } from './queries.generated';
import { Close } from '@material-ui/icons';
import queryString from 'query-string';
import { ButtonWrapper, LoadingComponent, RegisteredRunnersTable, PageTitle } from '@queensland-running/qr-components';
import { FilterRunners, Runner, UserAccount } from '@generated/schema';
import { useDownloadRunnersMutation } from './mutations.generated';
import { UpdateRunnerModal } from '@containers/modals/update-runner-modal';
import { DeleteRunnerModal } from '@containers/modals/delete-runner-modal';
import styled from 'styled-components';
import {RouteKeys, RouteLabels, Routes} from "@constants";

const CustomBlockquote = styled('blockquote')<{}>`
  && {
    padding: 0.5rem;
    margin: 1rem 0;
  }
`;

type BQProps = {
  onClose: () => void;
  action: any;
  children: ReactNode,
  bgColor: any
}
const BQ = ({ onClose, action, children, bgColor }: BQProps) => (
  <CustomBlockquote style={{ display: 'flex', backgroundColor: bgColor }}>
    <div>{children}</div>
    {action == null && onClose ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: 'auto',
          paddingLeft: 16,
        }}>
        <IconButton size="small" aria-label={'Close'} title={'Close'} color="inherit" onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </div>
    ) : null}
  </CustomBlockquote>
);

const QRAlert = styled(BQ)<{ bgColor?: string }>`
  && {
    border-radius: 20px;
    padding: 0.5rem;
    margin: 1rem 0;
    border-left: ${({ bgColor }) => bgColor && `5px solid ${bgColor}`};
    background-color: ${({ bgColor }) => bgColor && bgColor};
  }
`;

const enhance = compose(withRouter, React.memo);

const saveAs = (url: string) => {
  var anchorElem = document.createElement('a');
  // @ts-ignore
  anchorElem.style = 'display: none';
  anchorElem.href = url;
  // @ts-ignore
  anchorElem.download = true;

  document.body.appendChild(anchorElem);
  anchorElem.click();

  document.body.removeChild(anchorElem);
};

const RunnersView = ({ location: { search }, history }: RouteComponentProps) => {
  const title = RouteLabels[RouteKeys.runners];

  const [runner, setRunner] = useState<Runner | undefined>(undefined);
  const [deleteRunner, setDeleteRunner] = useState<Runner | undefined>(undefined);

  const filter = queryString.parse<FilterRunners>(search);
  // const pageSize = 25;
  const [download] = useDownloadRunnersMutation({
    variables: {
      input: {
        fileType: 'CSV',
      },
    },
    onCompleted: (data) => {
      const downloadUrl = data.runners && data.runners.download && data.runners.download.fileDownloadUrl;
      downloadUrl && saveAs(downloadUrl);
    },
  });
  const { data, loading } = useFetchRunnersQuery({
    variables: {
      input: {
        filter,
      },
    },
  });

  if (loading) {
    return <LoadingComponent />;
  }

  const runners = data && data.adminRunners && data.adminRunners.data;
  const accounts = data && data.adminRunners && data.adminRunners.accounts;
  const colors = ['#ededff'];

  return (
    <>
      <PageTitle title={title}/>
      {!accounts && (
      <ButtonWrapper>
        {/*<Button color="primary" variant="contained" onClick={() => download()}>*/}
        {/*  Add Runner*/}
        {/*</Button>*/}
        <Button color="secondary" variant="contained" onClick={() => download()}>
          Download
        </Button>
      </ButtonWrapper>
      )}
      {accounts && (
        <Grid item xs={12}>
          {accounts.length > 0 &&
            accounts.map((props) => {
              const { email, firstName, id, lastName, phoneNumber } = props as UserAccount;
              return (
              // @ts-ignore
                <QRAlert bgColor={colors[0]} key={id} onClose={() => history.push(Routes[RouteKeys.runners])}>
                  <Typography noWrap>
                    {firstName} {lastName}
                  </Typography>
                  <Typography variant={'subtitle2'}>
                    <b>Email:</b>&nbsp;{email}
                    <br/>
                    <b>Phone:</b>&nbsp;{phoneNumber}
                  </Typography>
                </QRAlert>
              );
            })}
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <RegisteredRunnersTable
            runners={runners as Runner[] || []}
            registrations={false}
            paginate={true}
            isAdmin={true}
            selectedDeleteRunner={deleteRunner}
            onClickRunner={(runner: Runner, isShiftKeyDown?: boolean) => {
              if (isShiftKeyDown) {
                setDeleteRunner(runner)
              } else {
                setRunner(runner);
              }
            }}
          />
        </Grid>
      </Grid>

      {runner!! && (
        <UpdateRunnerModal
          open={runner !== undefined}
          onClose={() => setRunner(undefined)}
          // setData={(updatedRunner: Runner) => {
          //   const updatedRunnerIndex = runners.findIndex(({ id }) => id === updatedRunner.id);
          //   const cloned = [...runners];
          //   cloned[updatedRunnerIndex] = updatedRunner;
          //   return setRunners([...cloned]);
          // }}
          runner={runner as Runner}
        />
      )}
      {deleteRunner!! && (
        <DeleteRunnerModal
          runner={deleteRunner as Runner}
          open={deleteRunner !== undefined}
          onClose={() => setDeleteRunner(undefined)}
        />
      )}
    </>
  );
};

// @ts-ignore
export const Runners = enhance(RunnersView);
