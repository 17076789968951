import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FieldValue, PageTitle } from '@queensland-running/qr-components';
import {RouteKeys, RouteLabels} from '@constants';
import UserContext from '../../../UserContext';
import * as env from '@constants/environment';
import { map, flow, toPairs, snakeCase, sortBy } from 'lodash/fp';

const flat = (obj: any, concatenator: string = '.'): object => (
  Object.keys(obj).reduce(
    (acc, key) => {
      if (typeof obj[key] !== 'object') {
        return {
          ...acc,
          [key]: obj[key],
        };
      }

      const flattenedChild = flat(obj[key], concatenator);

      return {
        ...acc,
        //@ts-ignore
        ...Object.keys(flattenedChild).reduce((childAcc, childKey) => ({ ...childAcc, [`${key}${concatenator}${childKey}`]: flattenedChild[childKey] }), {}),
      };
    },
    {},
  )
);


const ConfigView = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <PageTitle title={RouteLabels[RouteKeys.config]}/>
      <Grid container spacing={3} className="no-print">
        <Grid item xs={12}>
          <Typography variant="h2">Environment</Typography>
          <Grid container spacing={3}>
            {flow(
              flat,
              toPairs,
              sortBy(([label]) => label),
              map(([label, value]) =>
                <Grid item xs={12} md={6} key={label}>
                  <FieldValue label={snakeCase(label)} name={label} value={value.toString()}/>
                </Grid>
              ),
            )(env)}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">Access Token</Typography>
          <code style={{ wordBreak: 'break-all', fontSize: '12px' }}>
            {
              //@ts-ignore
              user.signInUserSession.accessToken.jwtToken}
          </code>
        </Grid>
      </Grid>
    </>
  );
};

export const Config = ConfigView;
