/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeclareWetWeatherMutationVariables = Types.Exact<{
  input: Types.WetWeatherInput;
}>;

export type DeclareWetWeatherMutation = { readonly __typename?: 'Mutation' } & {
  readonly sms?: Types.Maybe<
    { readonly __typename?: 'SMSMutation' } & {
      readonly wetWeather?: Types.Maybe<{ readonly __typename?: 'WetWeather' } & Pick<Types.WetWeather, 'success'>>;
    }
  >;
};

export const DeclareWetWeatherDocument = gql`
  mutation declareWetWeather($input: WetWeatherInput!) {
    sms {
      wetWeather(input: $input) {
        success
      }
    }
  }
`;
export type DeclareWetWeatherMutationFn = Apollo.MutationFunction<
  DeclareWetWeatherMutation,
  DeclareWetWeatherMutationVariables
>;
export type DeclareWetWeatherComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeclareWetWeatherMutation, DeclareWetWeatherMutationVariables>,
  'mutation'
>;

export const DeclareWetWeatherComponent = (props: DeclareWetWeatherComponentProps) => (
  <ApolloReactComponents.Mutation<DeclareWetWeatherMutation, DeclareWetWeatherMutationVariables>
    mutation={DeclareWetWeatherDocument}
    {...props}
  />
);

export type DeclareWetWeatherProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<DeclareWetWeatherMutation, DeclareWetWeatherMutationVariables>;
} &
  TChildProps;
export function withDeclareWetWeather<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeclareWetWeatherMutation,
    DeclareWetWeatherMutationVariables,
    DeclareWetWeatherProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeclareWetWeatherMutation,
    DeclareWetWeatherMutationVariables,
    DeclareWetWeatherProps<TChildProps, TDataName>
  >(DeclareWetWeatherDocument, {
    alias: 'declareWetWeather',
    ...operationOptions,
  });
}

/**
 * __useDeclareWetWeatherMutation__
 *
 * To run a mutation, you first call `useDeclareWetWeatherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclareWetWeatherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declareWetWeatherMutation, { data, loading, error }] = useDeclareWetWeatherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclareWetWeatherMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclareWetWeatherMutation, DeclareWetWeatherMutationVariables>,
) {
  return Apollo.useMutation<DeclareWetWeatherMutation, DeclareWetWeatherMutationVariables>(
    DeclareWetWeatherDocument,
    baseOptions,
  );
}
export type DeclareWetWeatherMutationHookResult = ReturnType<typeof useDeclareWetWeatherMutation>;
export type DeclareWetWeatherMutationResult = Apollo.MutationResult<DeclareWetWeatherMutation>;
export type DeclareWetWeatherMutationOptions = Apollo.BaseMutationOptions<
  DeclareWetWeatherMutation,
  DeclareWetWeatherMutationVariables
>;
