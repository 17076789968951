import React from 'react';
import {FormikProps} from 'formik';
import { InferType, object, ref, string } from 'yup';
import { compose } from 'recompose';
import { withSnackbar, WithSnackbarProps } from 'notistack';

const validationSchema = object().shape({
  password: string()
    .min(8, 'Password must be at least 8 characters in length')
    .max(128, 'Password cannot be longer than 128 characters in length')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,128})/,
      'Password must contain: Lower case letters (a-z), Upper case letters (A-Z), Numbers (i.e. 0-9), and Special characters (e.g. !@#$%^&*)',
    )
    .required('Required'),
  passwordConfirm: string()
    .oneOf([ref('password'), null], "Passwords don't match!")
    .required('Required'),
});

type ChangePasswordProps = { open: boolean; onClose: () => void };
type FormValues = InferType<typeof validationSchema>;
type ChangePasswordModalViewProps = ChangePasswordProps & FormikProps<FormValues> & WithSnackbarProps;

const enhance = compose<ChangePasswordModalViewProps, ChangePasswordProps>(
  withSnackbar,
  React.memo,
);

const ChangePasswordModalView = (
  // {
  // errors,
  // isValid,
  // values,
  // touched,
  // handleChange,
  // handleBlur,
  // isSubmitting,
  // onClose,
  // resetForm,
  // status,
  // open,
  // enqueueSnackbar,
// }: ChangePasswordModalViewProps
) => {
  // useChangePasswordMutation()

  // const formik = useFormik({
  //   handleSubmit: async (values, { setStatus, setSubmitting, props: { changePassword, enqueueSnackbar } }) => {
  //     setStatus(null);
  //
  //     changePassword({
  //       variables: {
  //         input: {
  //           password: values.password,
  //         },
  //       },
  //     })
  //       .then(() => {
  //         setSubmitting(false);
  //         setStatus('PASSWORD_CHANGED');
  //       })
  //       .catch((e: ApolloError & Error) => {
  //         setSubmitting(false);
  //         setStatus({
  //           registration: {
  //             type: 'error',
  //             message:
  //               e.graphQLErrors[0] && e.graphQLErrors[0].message
  //                 ? e.graphQLErrors[0].message
  //                 : e.message
  //                 ? e.message
  //                 : 'We were unable to change your password, please contact your account manager',
  //           },
  //         });
  //         console.error(e);
  //       });
  //   },
  //   validationSchema,
  // });

  // if (status === 'PASSWORD_CHANGED') {
  //   enqueueSnackbar(`Your password has changed, you will be directed to sign in with your new password.`, {
  //     variant: 'success',
  //     onExit: () => Auth.signOut(),
  //   });
  // }

  // let forgetAndClose = () => {
    // resetForm();
    // onClose();
  // };

  return <></>
  // return (
  //   <Dialog
  //     open={open}
  //     onClose={forgetAndClose}
  //     aria-labelledby="alert-dialog-title"
  //     aria-describedby="alert-dialog-description">
  //     <Form>
  //       <DialogTitle id="alert-dialog-title">{'Change Password'}</DialogTitle>
  //       <DialogContent id="alert-dialog-description">
  //         <Typography>You will be prompted to log in once you have successfully changed your password.</Typography>
  //         <br />
  //         <Grid container spacing={3}>
  //           <Grid item xs={12}>
  //             <TextField
  //               error={!!(errors.password && touched.password)}
  //               label="Password"
  //               name="password"
  //               type="password"
  //               value={values.password}
  //               onChange={handleChange}
  //               onBlur={handleBlur}
  //               helperText={errors.password && touched.password && errors.password}
  //               disabled={isSubmitting}
  //               margin="normal"
  //               fullWidth
  //             />
  //           </Grid>
  //           <Grid item xs={12}>
  //             <TextField
  //               error={!!(errors.passwordConfirm && touched.passwordConfirm)}
  //               label="Confirm Password"
  //               name="passwordConfirm"
  //               value={values.passwordConfirm}
  //               onChange={handleChange}
  //               onBlur={handleBlur}
  //               type="password"
  //               helperText={errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm}
  //               disabled={isSubmitting}
  //               margin="normal"
  //               fullWidth
  //             />
  //           </Grid>
  //           <Grid item xs={12}>
  //             {/*{map((a: { type: string; message: string }) => (*/}
  //             {/*  <Blockquote key={a.message.substr(0, 6)} color={a.type === 'success' ? 'primary' : 'error'}>*/}
  //             {/*    <p>{a.message}</p>*/}
  //             {/*  </Blockquote>*/}
  //             {/*))(status)}*/}
  //           </Grid>
  //         </Grid>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button color="primary" autoFocus onClick={forgetAndClose}>
  //           Close
  //         </Button>
  //         <Button color="primary" type="submit" variant="contained" disabled={!isValid || isSubmitting}>
  //           Change Password
  //         </Button>
  //       </DialogActions>
  //     </Form>
  //   </Dialog>
  // );
};

export const ChangePasswordModal = enhance(ChangePasswordModalView);
