import React, { useState } from 'react';
import { compose } from 'recompose';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Administrator, Role } from '@generated/schema';
import { useFetchAdministratorsQuery } from './queries.generated';
import { AdministratorsTable, ErrorComponent, LoadingComponent, PageTitle } from '@queensland-running/qr-components';
import { RouteKeys, RouteLabels } from '@constants';
import { UpdateAdministratorModal } from '../../modals/update-administrator-modal';

type AdministratorsData = {
  administrators: Administrator[];
  roles: Role[];
};

type AdministratorsViewProps = {};

const enhance = compose<AdministratorsViewProps, AdministratorsData>(withRouter, React.memo);

const AdministratorsView = () => {
  const [roleFilter, setRoleFilter] = useState('');
  const [selectedUser, setSelectedUser] = useState<Administrator>();
  const [changeUserDetailsModalOpen, setChangeUserDetailsModalOpen] = useState(false);

  const onChangeDetailsFn = (user: Administrator) => {
    setChangeUserDetailsModalOpen(true);
    setSelectedUser(user);
  };

  const { data, loading, error } = useFetchAdministratorsQuery({
    variables: {
      input: {
        role: roleFilter,
      },
    },
  });

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent />;
  }

  return (
    <>
      <PageTitle title={RouteLabels[RouteKeys.administrators]} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AdministratorsTable
            administrators={data && data.administrators as Administrator[] || []}
            onChangeDetails={onChangeDetailsFn}
            roleFilter={roleFilter}
            setRoleFilter={setRoleFilter}
          />
        </Grid>
      </Grid>
      {changeUserDetailsModalOpen && selectedUser && (
        <UpdateAdministratorModal
          user={selectedUser}
          open={changeUserDetailsModalOpen}
          onClose={() => setChangeUserDetailsModalOpen(false)}
          roles={data && data.roles as Role[] || []}
        />
      )}
    </>
  );
};

export const Administrators = enhance(AdministratorsView);
