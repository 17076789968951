import * as env from '@constants/environment';
import { forEach } from 'lodash/fp';
import { Auth } from 'aws-amplify';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: env.graphqlApiUri,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // @ts-ignore
  const token = await Auth.currentSession().then((session) => session.idToken.jwtToken);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    forEach(console.error)(graphQLErrors);
  }

  if (networkError) {
    console.error(networkError);
  }
});

export const createClient = () =>
  new ApolloClient({
    link: errorLink.concat(authLink).concat(httpLink),
    connectToDevTools: env.isOffline,
    cache: new InMemoryCache(),
  });
