import React, { ChangeEvent } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { FieldArray, Form, FormikProps, withFormik } from 'formik';
import { array, object, string } from 'yup';
import { compose, withProps } from 'recompose';
import {
  UpdateAdministratorProps as UpdateAdministratorMutationProps,
  withUpdateAdministrator as withUpdateAdministratorMutation,
} from './mutations.generated';
import { Administrator, Role } from '../../@generated/schema';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { ApolloError } from '@apollo/client';
import { InferType } from 'prop-types';

const validationSchema = object().shape({
  firstName: string().required('A first name is required'),
  lastName: string().required('A last name is required'),
  roles: array()
    .of(string())
    .notRequired(),
});

type UpdateAdministratorProps = {
  user: Administrator;
  userId?: string | null;
  open: boolean;
  onClose: () => void;
  roles?: Role[];
};
type FormValues = InferType<typeof validationSchema>;
type UpdateAdministratorModalViewProps = UpdateAdministratorProps & FormikProps<FormValues>;

const enhance = compose<UpdateAdministratorModalViewProps, UpdateAdministratorProps>(
  withSnackbar,
  withUpdateAdministratorMutation<UpdateAdministratorMutationProps & WithSnackbarProps>({
    alias: 'withUpdateAdministratorMutation',
    name: 'updateAdministrator',
    options: ({ enqueueSnackbar, closeSnackbar }) => ({
      onCompleted: ({ administrator }) =>
        enqueueSnackbar(`${administrator!.updateAdministrator!.firstName}'s details have been updated successfully.`, {
          variant: 'success',
        }),
      onError: (error: ApolloError) =>
        enqueueSnackbar(error.message, {
          variant: 'error',
          persist: true,
          action: (key: string | number) => (
            <Button
              onClick={() => {
                closeSnackbar(key);
              }}>
              Dismiss
            </Button>
          ),
        }),
    }),
  }),
  withProps({
    mapUserToValues: (user: Administrator, roles: Role[]): FormValues => {
      if (user) {
        return {
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          ...(roles && { roles: (user.roles && user.roles.map((r: Role) => r.id || '')) || [] }),
        };
      }

      return {
        firstName: '',
        lastName: '',
        ...(roles ? { roles: [] } : { roles: null }),
      };
    },
  }),
  withFormik<
    {
      updateAdministrator: any;
      mapUserToValues: (user: Administrator, roles?: Role[]) => FormValues;
    } & UpdateAdministratorMutationProps &
      UpdateAdministratorProps,
    FormValues
  >({
    mapPropsToValues: ({ user, mapUserToValues, roles }): FormValues => mapUserToValues(user, roles),
    enableReinitialize: true,
    handleSubmit: async (values, { setStatus, resetForm, props: { updateAdministrator, onClose, user } }) => {
      setStatus(null);

      await updateAdministrator({
        variables: {
          input: {
            id: user.id,
            firstName: values.firstName,
            lastName: values.lastName,
            roles: values.roles,
          },
        },
      });

      onClose();
      resetForm();
    },
    validationSchema,
  }),
  React.memo,
);

const UpdateAdministratorModalView = ({
  user,
  errors,
  roles,
  isValid,
  values,
  touched,
  handleChange,
  handleBlur,
  isSubmitting,
  onClose,
  open,
  resetForm,
}: UpdateAdministratorModalViewProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        resetForm();
        onClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Form>
        <DialogTitle id="alert-dialog-title">{'Update Details'}</DialogTitle>
        <DialogContent id="alert-dialog-description">
          {user && (
            <Grid container wrap="nowrap" spacing={3}>
              <Grid item xs zeroMinWidth>
                <Typography variant={'h5'} noWrap>
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography variant={'subtitle2'}>{user.email}</Typography>
                <Typography variant={'subtitle2'}>
                  <b>ID:</b>&nbsp;{user.id}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={!!(errors.firstName && touched.firstName)}
                label="First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.firstName && touched.firstName && errors.firstName}
                disabled={isSubmitting}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={!!(errors.lastName && touched.lastName)}
                label="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.lastName && touched.lastName && errors.lastName}
                disabled={isSubmitting}
                margin="normal"
                fullWidth
              />
            </Grid>
            {roles && (
              <Grid item xs={12}>
                <FieldArray
                  name="roles"
                  render={(arrayHelpers) => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Access</FormLabel>
                      <FormGroup>
                        {roles.map((r: Role) => (
                          <FormControlLabel
                            key={r.id!}
                            label={r.name}
                            control={
                              <Checkbox
                                name={r.id!}
                                checked={values.roles && values.roles.includes(r.id!)}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  if (e.target.checked) {
                                    arrayHelpers.push(r.id);
                                  } else {
                                    //@ts-ignore
                                    const idx = values.roles.indexOf(r.id!);
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              resetForm();
              onClose();
            }}>
            Close
          </Button>
          <Button color="primary" type="submit" variant="contained" disabled={!isValid || isSubmitting}>
            Update
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export const UpdateAdministratorModal = enhance(UpdateAdministratorModalView);
