import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { useFormik } from 'formik';
import { AuthenticationTitle } from '@queensland-running/qr-components';

const SignUp = ({
  updateErrorMessage,
  switchState,
}: RouteComponentProps & { updateErrorMessage: any; switchState: any }) => {
  //@ts-ignore
  const [showConfirmation, setShowConfirmation] = useState(false);

  const signInForm = useFormik({
    initialValues: {
      emailAddress: '',
      password: '',
      firstName: '',
      lastName: '',
    },
    onSubmit: (values) => {
      Auth.signUp({
        username: values.emailAddress,
        password: values.password,
        attributes: {
          email: values.emailAddress,
          given_name: values.firstName,
          family_name: values.lastName,
          // 'custom:family_name': values.lastName, // custom attribute, not standard
        },
      })
        .then(() => {
          setShowConfirmation(true);
          updateErrorMessage();
        })
        .catch((err) => {
          updateErrorMessage(err.message);
        });
    },
  });

  return (
    <>
      <AuthenticationTitle title={'Create a new account'}/>
      {!showConfirmation && (
        <form onSubmit={signInForm.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                label="Email Address"
                name="emailAddress"
                onChange={signInForm.handleChange}
                value={signInForm.values.emailAddress}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="firstName"
                label="First Name"
                type="firstName"
                onChange={signInForm.handleChange}
                value={signInForm.values.firstName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="lastName"
                label="Last Name"
                type="lastName"
                onChange={signInForm.handleChange}
                value={signInForm.values.lastName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="password"
                label="Password"
                type="password"
                onChange={signInForm.handleChange}
                value={signInForm.values.password}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" color="primary" variant="contained" fullWidth>
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {showConfirmation && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              We have sent a confirmation link to your email. Please confirm your email address by clicking on the link
              we have sent to you.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SignUp;
