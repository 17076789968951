const getEnv = (key: string): string => {
  const env = process.env[key];

  if (!env) {
    throw new Error(`Missing environment variable ${key}`);
  }

  if (!(process.env.NODE_ENV !== 'production' || /true/i.test(process.env.CI || ''))) {
    console.log(`Loading environment variable ${key}: ${JSON.stringify(env)}`);
  }

  return env;
};

export const isOffline = /true/i.test(getEnv('REACT_APP_IS_OFFLINE'));
export const stage = getEnv('REACT_APP_STAGE');
// export const currentVersion = getEnv('REACT_APP_VERSION');

// ENDPOINTS
export const graphqlApiUri = getEnv('REACT_APP_GRAPHQL_API_URI');

// AWS
export const aws = {
  region: getEnv('REACT_APP_REGION'),
  userPoolId: getEnv('REACT_APP_USER_POOL_ID'),
  userPoolWebClientId: getEnv('REACT_APP_USER_POOL_WEB_CLIENT_ID'),
};

// SENTRY
export const sentry = {
  dsn: getEnv('REACT_APP_SENTRY_DSN'),
  projects: {
    webClientUrl: getEnv('REACT_APP_SENTRY_WEB_CLIENT_PROJECT'),
    adminWebClientUrl: getEnv('REACT_APP_SENTRY_ADMIN_WEB_CLIENT_PROJECT'),
  },
};

// SEQ
// export const seq = {
//   url: getEnv('REACT_APP_SEQ_URL'),
// };
