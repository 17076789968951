import React, { useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import {array, boolean, date, mixed, number, object, string} from 'yup';
import { compose } from 'recompose';
import { formatCost, Modal, TeamRunnersInput } from '@queensland-running/qr-components';
import { CreateRockettRelayTeamMutation, useCreateRockettRelayTeamMutation } from './mutations.generated';
import { MutationUpdaterFn } from '@apollo/client/core';
import { ApolloError } from '@apollo/client';
import {
  PaymentType,
  RegisterRockettRelaysRunner,
  RelayAgeGroup,
  RelayGender,
  RelayType,
  RockettRelaysFees,
} from '@generated/schema';
import { useSnackbar } from 'notistack';
import moment from 'moment';

type CreateEventProps = {
  open: boolean;
  onClose: () => void;
  eventId: string;
  onCreateTeam: MutationUpdaterFn<CreateRockettRelayTeamMutation>;
  fees?: RockettRelaysFees | undefined | null;
  eventDate: string | Date | undefined;
};

const enhance = compose<CreateEventProps, CreateEventProps>(React.memo);

const CreateRockettRelayTeamModalView = ({ onClose, open, eventId, fees, onCreateTeam, eventDate }: CreateEventProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [registerTeam] = useCreateRockettRelayTeamMutation({
    update: onCreateTeam,
    onCompleted: ({ competition }) => {
      enqueueSnackbar(
        `The ${
          competition!.registerTeamForRockettRelays!.teamDetails!.teamName
        } team has been created successfully.`,
        {
          variant: 'success',
        },
      );
    },
    onError: (error: ApolloError) => {},
    // enqueueSnackbar(error.message, {
    //   variant: 'error',
    //   persist: true,
    //   action: (key) => (
    //     <Button
    //       onClick={() => {
    //         closeSnackbar(key);
    //       }}>
    //       Dismiss
    //     </Button>
    //   ),
    // })
  });

  const { errors, values, touched, setFieldValue, handleSubmit, handleChange, handleBlur, isSubmitting } = useFormik({
    initialValues: {
      teamName: '',
      relay: '',
      gender: '',
      ageGroup: '',
      weeklyCompetitors: false,
      runners: [],
      paymentMethod: '',
    },
    validationSchema: object().shape({
      teamName: string()
        .max(45, 'Please use a team name shorter no longer than 45 characters.')
        .required('A team name is required'),
      relay: mixed<RelayType>()
        .oneOf(['ONE_HOUR_RELAY', 'TWO_HOUR_RELAY'])
        .required('Please select a relay type'),
      ageGroup: mixed<RelayAgeGroup>().when('relay', (relay: string, schema: any) => {
        if (relay === 'ONE_HOUR_RELAY') {
          return schema.oneOf(
            ['TEN_AND_UNDER', 'FOURTEEN_AND_UNDER'],
            `Age group must be one of the following: 10 & under, 14 and under`,
          );
        }
        if (relay === 'TWO_HOUR_RELAY') {
          return schema.oneOf(['OPEN', 'MASTERS'], 'Age group must be one of the following: Open, Masters');
        }
        return schema;
      }),
      gender: mixed<RelayGender>().oneOf(['MALE', 'FEMALE'], 'Gender must be one of the following: Male, Female'),
      paymentMethod: mixed<PaymentType>().oneOf(['CASH', 'BANK_TRANSFER']),
      weeklyCompetitors: boolean(),
      runners: array()
        .of(
          object().shape({
            position: number().required('Required'),
            name: string(),
            dateOfBirth: date()
              .transform((_, rawValue) => rawValue ? moment(rawValue, ['yyyy-mm-dd']).toDate() : undefined)
              .min(moment(new Date(1900, 0, 0)).toDate(), 'Date must be later than 01/01/1900')
              .max(moment(new Date()).toDate(), 'Date must be in the past.')
            .typeError('Please enter a valid date'),
            gender: mixed<RelayGender>()
              .oneOf(['MALE', 'FEMALE'], 'Gender must be one of the following: Male, Female')
              .notRequired(),
          }),
        )
        .min(1, 'Please select at least 1 runner.')
        .when('relay', (relay: string, schema: any) => {
          if (relay === 'ONE_HOUR_RELAY') {
            return schema.max(3, 'No more than 3 runners can be selected for this relay.')
          }
          if (relay === 'TWO_HOUR_RELAY') {
            return schema.max(4, 'No more than 4 runners can be selected for this relay.')
          }
        })
    }),
    onSubmit: (values) => {
      registerTeam({
        variables: {
          input: {
            registrationId: '',
            locationId: '',
            // eventId,
            // type: 'ADMINISTRATOR',
              teamName: values.teamName,
              relay: values.relay as RelayType,
              ageGroup: values.ageGroup === '' ? null : (values.ageGroup as RelayAgeGroup),
              gender: values.gender === '' ? null : (values.gender as RelayGender),
              runners: values.runners.map((runner: RegisterRockettRelaysRunner) => ({
                position: runner.position,
                name: runner.name === '' ? null : runner.name,
                dateOfBirth: runner.dateOfBirth && moment(runner.dateOfBirth!).format('YYYY-MM-DD'),
                gender: runner.gender ? (runner.gender as RelayGender) : null
              })),
          },
        },
      }).then(() => {
        onClose();
      });
    },
  });

  let teamFee: number = 0;

  if (fees && values.relay) {
    const relayFee = fees[values.relay as RelayType];
    teamFee = values.weeklyCompetitors ? relayFee.weeklyCompetitor : relayFee.nonWeeklyCompetitor;
  }

  return (
    <Modal
      onClose={onClose}
      open={open}
      maxWidth="md"
      title={'Create Team'}
      primaryAction={handleSubmit}
      primaryActionText={'Create'}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!(errors.teamName && touched.teamName)}
            label="Team Name"
            name="teamName"
            value={values.teamName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.teamName && touched.teamName && errors.teamName}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl margin="normal" fullWidth error={!!errors.relay}>
            <InputLabel id="select-relay-label">Relay</InputLabel>
            <Select
              labelId="select-relay-label"
              id="relay-select"
              name="relay"
              value={values.relay}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}>
              <MenuItem value={'ONE_HOUR_RELAY'}>1 Hour Relay</MenuItem>
              <MenuItem value={'TWO_HOUR_RELAY'}>2 Hour Relay</MenuItem>
            </Select>
            <FormHelperText>{errors.relay}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TeamRunnersInput
            label="Team Runners"
            name="runners"
            //@ts-ignore
            errors={errors.runners}
            relayType={values.relay as RelayType}
            value={values.runners}
            //@ts-ignore
            onChange={(runners) => setFieldValue('runners', runners)}
            eventDate={eventDate}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.weeklyCompetitors}
                onChange={handleChange}
                onBlur={handleBlur}
                name="weeklyCompetitors"
              />
            }
            label="Weekly Competitors?"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" fullWidth error={!!errors.gender}>
                <InputLabel id="select-gender-label">Gender</InputLabel>
                <Select
                  labelId="select-gender-label"
                  id="gender-select"
                  name="gender"
                  value={values.gender}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}>
                  <MenuItem value={'MALE'}>Male</MenuItem>
                  <MenuItem value={'FEMALE'}>Female</MenuItem>
                </Select>
                <FormHelperText>{errors.gender}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" fullWidth error={!!errors.ageGroup}>
                <InputLabel id="select-age-group-label">Age Group</InputLabel>
                <Select
                  labelId="select-age-group-label"
                  id="age-group-select"
                  name="ageGroup"
                  value={values.ageGroup}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}>
                  <MenuItem value={'OPEN'} style={{ color: values.relay === 'ONE_HOUR_RELAY' ? 'gray' : 'black' }}>
                    Open
                  </MenuItem>
                  <MenuItem value={'MASTERS'} style={{ color: values.relay === 'ONE_HOUR_RELAY' ? 'gray' : 'black' }}>
                    Masters
                  </MenuItem>
                  <MenuItem value={'TEN_AND_UNDER'} style={{ color: values.relay === 'TWO_HOUR_RELAY' ? 'gray' : 'black' }}>
                    10 & under
                  </MenuItem>
                  <MenuItem
                    value={'FOURTEEN_AND_UNDER'}
                    style={{ color: values.relay === 'TWO_HOUR_RELAY' ? 'gray' : 'black' }}>
                    14 & under
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.ageGroup}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <ul>
                <li><Typography>Mixed gender teams are considered <b>MALE</b> teams</Typography></li>
                <li><Typography>Mixed age teams in One Hour Relay are considered <b>14 & under</b> teams</Typography></li>
                <li><Typography>Mixed age teams in Two Hour Relay are considered <b>Open</b> teams</Typography></li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="select-payment-method-label">Payment Method</InputLabel>
            <Select
              labelId="select-payment-method-label"
              id="payment-method-select"
              name="paymentMethod"
              value={values.paymentMethod}
              onChange={handleChange}
              onBlur={handleBlur}>
              <MenuItem value={'CASH'}>Cash</MenuItem>
              <MenuItem value={'BANK_TRANSFER'}>Bank Transfer</MenuItem>
            </Select>
          </FormControl>
        </Grid>


      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary" style={{ float: 'right', marginBottom: '1.8rem' }}>
          {formatCost(teamFee)}
        </Typography>
      </Grid>

    </Modal>
  );
};

export const CreateRockettRelayTeamModal = enhance(CreateRockettRelayTeamModalView);
