import React, {useState} from 'react';
import {Auth} from 'aws-amplify';
import {Button, Grid, TextField, Typography} from '@material-ui/core';
import {RouteComponentProps} from "react-router";
import {useFormik} from "formik";
import { AuthenticationTitle } from '@queensland-running/qr-components';

export const ForgotPassword = ({updateErrorMessage, switchState}: RouteComponentProps & { updateErrorMessage: any, switchState: any }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  let errorCodes = {
    "UserNotFoundException": "User not found"
  };

  const forgotPasswordForm = useFormik({
    initialValues: {
      emailAddress: '',
    },
    onSubmit: (values) => {
      Auth.forgotPassword(values.emailAddress)
        .then(() => {
          setEmailAddress(values.emailAddress);
          setShowConfirmation(true)
          updateErrorMessage();
        })
        .catch(err => {
          // @ts-ignore
          const message: string = errorCodes[err.code] || err.message;
          updateErrorMessage(message)
        })
    },
  });

  const newPasswordForm = useFormik({
    initialValues: {
      confirmationCode: '',
      password: '',
    },
    onSubmit: (values) => {
      Auth.forgotPasswordSubmit(emailAddress, values.confirmationCode, values.password)
        .then(() => {
          updateErrorMessage();
          switchState('showSignIn');
        })
        .catch(err => {
          console.log('error resetting password...: ', err);
          // @ts-ignore
          const message: string = errorCodes[err.code] || err.message;

          updateErrorMessage(message)
        })
    },
  });

  return (
    <>
      {!showConfirmation && (
        <form onSubmit={forgotPasswordForm.handleSubmit}>
          <AuthenticationTitle title='Reset your password'/>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                We will sent a confirmation code to your email. Please confirm your email address by clicking on the link
                we have sent to you.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="emailAddress"
                label="Email Address"
                onChange={forgotPasswordForm.handleChange}
                value={forgotPasswordForm.values.emailAddress}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button type="submit" color="primary" variant="contained" fullWidth>
                Send Verification Code
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {showConfirmation && (
        <form onSubmit={newPasswordForm.handleSubmit}>
          <AuthenticationTitle title='New password'/>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                name="confirmationCode"
                label="Confirmation Code"
                onChange={newPasswordForm.handleChange}
                value={newPasswordForm.values.confirmationCode}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name="password"
                label="Password"
                type="password"
                onChange={newPasswordForm.handleChange}
                value={newPasswordForm.values.password}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" color="primary" variant="contained" fullWidth>
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}
