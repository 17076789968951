import React from 'react';
import { map } from 'lodash/fp';
import {Avatar, Button, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import { compose } from 'recompose';
import { Administrator, Role } from '@generated/schema';
import { Person } from '@material-ui/icons';
import styled from 'styled-components';
import { formatDateTime } from '@queensland-running/qr-components';

type AdministratorTableProps = AdministratorTableViewProps;
type AdministratorTableViewProps = {
  onChangeDetails: (user: Administrator) => void;
  administrators: ReadonlyArray<Administrator>;
};

const enhance = compose<AdministratorTableViewProps, AdministratorTableProps>(React.memo);

const StyledAvatar = styled(Avatar)`
&& {
  color: ${({ theme }) => theme.palette.getContrastText(theme.palette.secondary.main)}
  background-color: ${({ theme }) => theme.palette.secondary.main}
  }
`;

const Roles = styled.ul`
  li:not(:last-child) {
    margin-bottom: 5px;
  }
  padding-left: 20px;
`;

export const AdministratorTableView = ({ administrators, onChangeDetails }: AdministratorTableViewProps) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell style={{ paddingLeft: 0, paddingRight: 0 }} colSpan={2}>
          User
        </TableCell>
        <TableCell>Created</TableCell>
        <TableCell>Access</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {map((currentUser: Administrator) => {
        const { email, createdAt, picture, firstName, lastName, roles } = currentUser;
        return (
          <TableRow key={email!}>
            <TableCell style={{paddingLeft: 0, paddingRight: 0, minWidth: '40px', width: '40px', maxWidth: '40px'}}>
              <StyledAvatar src={picture!}>
                <Person/>
              </StyledAvatar>
            </TableCell>
            <TableCell>
              {firstName} {lastName}
              <br/>
              <small>{email}</small>
            </TableCell>
            <TableCell>{createdAt && formatDateTime(createdAt)}</TableCell>
            <TableCell>
              <Roles>{roles && roles.map(({name}: Role) => <li key={name || ''}>{name}</li>)}</Roles>
            </TableCell>
            <TableCell>
              <Button color="primary" type="submit" variant="contained" onClick={() => onChangeDetails(currentUser)} fullWidth>
                Manage
              </Button>
            </TableCell>
          </TableRow>
        );
      })(administrators)}
    </TableBody>
  </Table>
);

export const AdministratorTable = enhance(AdministratorTableView);
