/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchCompetitionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type FetchCompetitionQuery = { readonly __typename?: 'Query' } & {
  readonly competition?: Types.Maybe<
    | ({ readonly __typename?: 'AllSchoolsChampionships' } & Pick<Types.AllSchoolsChampionships, 'id' | 'day'>)
    | ({ readonly __typename?: 'NoCompetition' } & Pick<Types.NoCompetition, 'id' | 'day'>)
    | ({ readonly __typename?: 'RockettRelays' } & Pick<Types.RockettRelays, 'id' | 'day'>)
    | ({ readonly __typename?: 'WeeklyCompetition' } & Pick<
        Types.WeeklyCompetition,
        'agenda' | 'program' | 'results' | 'status' | 'id' | 'day'
      > & {
          readonly weeklyCompetitionRegistrations?: Types.Maybe<
            { readonly __typename?: 'WeeklyCompetitionRegistrationListData' } & {
              readonly data?: Types.Maybe<
                ReadonlyArray<
                  Types.Maybe<
                    { readonly __typename?: 'WeeklyCompetitionRegistration' } & Pick<
                      Types.WeeklyCompetitionRegistration,
                      'id'
                    >
                  >
                >
              >;
            }
          >;
          readonly programItems?: Types.Maybe<
            ReadonlyArray<{ readonly __typename?: 'ProgramItem' } & Pick<Types.ProgramItem, 'time' | 'race'>>
          >;
        })
    | ({ readonly __typename?: 'WinterChampionships' } & Pick<Types.WinterChampionships, 'id' | 'day'>)
  >;
};

export const FetchCompetitionDocument = gql`
  query fetchCompetition($id: String!) {
    competition(id: $id) {
      id
      day
      ... on WeeklyCompetition {
        weeklyCompetitionRegistrations {
          data {
            id
          }
        }
        programItems {
          time
          race
        }
        agenda
        program
        results
        status
      }
    }
  }
`;
export type FetchCompetitionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
  'query'
> &
  ({ variables: FetchCompetitionQueryVariables; skip?: boolean } | { skip: boolean });

export const FetchCompetitionComponent = (props: FetchCompetitionComponentProps) => (
  <ApolloReactComponents.Query<FetchCompetitionQuery, FetchCompetitionQueryVariables>
    query={FetchCompetitionDocument}
    {...props}
  />
);

export type FetchCompetitionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchCompetitionQuery, FetchCompetitionQueryVariables>;
} &
  TChildProps;
export function withFetchCompetition<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchCompetitionQuery,
    FetchCompetitionQueryVariables,
    FetchCompetitionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchCompetitionQuery,
    FetchCompetitionQueryVariables,
    FetchCompetitionProps<TChildProps, TDataName>
  >(FetchCompetitionDocument, {
    alias: 'fetchCompetition',
    ...operationOptions,
  });
}

/**
 * __useFetchCompetitionQuery__
 *
 * To run a query within a React component, call `useFetchCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompetitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchCompetitionQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
) {
  return Apollo.useQuery<FetchCompetitionQuery, FetchCompetitionQueryVariables>(FetchCompetitionDocument, baseOptions);
}
export function useFetchCompetitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchCompetitionQuery, FetchCompetitionQueryVariables>(
    FetchCompetitionDocument,
    baseOptions,
  );
}
export type FetchCompetitionQueryHookResult = ReturnType<typeof useFetchCompetitionQuery>;
export type FetchCompetitionLazyQueryHookResult = ReturnType<typeof useFetchCompetitionLazyQuery>;
export type FetchCompetitionQueryResult = Apollo.QueryResult<FetchCompetitionQuery, FetchCompetitionQueryVariables>;
