import React from 'react';
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Form, FormikProps, withFormik } from 'formik';
import { Competition, EventStatus, Park } from '@generated/schema';
import { object, string } from 'yup';
import { branch, compose, renderComponent } from 'recompose';
import {
  UpdateEventProps as UpdateEventMutationProps,
  withUpdateEvent as withUpdateEventMutation,
} from './mutations.generated';
import { FetchUpdateEventQueryVariables, withFetchUpdateEvent } from './queries.generated';
import { ApolloError } from '@apollo/client';
import { map } from 'lodash/fp';
import { Blockquote, LoadingComponent } from '@queensland-running/qr-components';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { DataValue } from '@apollo/client/react/hoc';
import { InferType } from 'prop-types';

const validationSchema = object().shape({
  agenda: string().required('An agenda is required'),
  parkKey: string().notRequired(),
  program: string().notRequired(),
  status: string().notRequired(),
});

type UpdateEventProps = { event: Competition; open: boolean; onClose: () => void };
type FormValues = InferType<typeof validationSchema>;
type UpdateEventModalViewProps = UpdateEventProps & FormikProps<FormValues> & UpdateEventQueryProps;

type UpdateEventQueryProps = {
  updateEventQuery: NonNullable<DataValue<{ parks: Park[]; statuses: EventStatus[] }, FetchUpdateEventQueryVariables>>;
};

const enhance = compose<UpdateEventModalViewProps, UpdateEventProps>(
  withSnackbar,
  withFetchUpdateEvent({
    name: 'updateEventQuery',
  }),
  branch<UpdateEventQueryProps>(
    ({ updateEventQuery: { loading } }: UpdateEventQueryProps) => loading,
    renderComponent(() => (
      <Backdrop open={true} style={{ zIndex: 1 }}>
        <LoadingComponent />
      </Backdrop>
    )),
  ),
  // Handle Error State after query
  withUpdateEventMutation<UpdateEventMutationProps & WithSnackbarProps & UpdateEventProps>({
    alias: 'withUpdateEventMutation',
    name: 'updateEvent',
    options: ({ enqueueSnackbar, closeSnackbar, event }) => ({
      onCompleted: () => enqueueSnackbar(`Details have been updated for ${event.id}`, { variant: 'success' }),
      onError: (error: ApolloError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <Button
              onClick={() => {
                closeSnackbar(key);
              }}>
              Dismiss
            </Button>
          ),
        });
      },
    }),
  }),
  withFormik<{ updateEvent: any } & UpdateEventMutationProps & UpdateEventProps, FormValues>({
    handleSubmit: async (values, { setStatus, setSubmitting, props: { event: loadedEvent, updateEvent, onClose } }) => {
      setStatus(null);

      // const { __typename, results, park, ...event } = loadedEvent;

      updateEvent({
        variables: {
          input: {
            // ...event,
            id: loadedEvent.id,
            status: values.status === 'ACTIVE' ? null : values.status,
            program: values.program === '' ? null : values.program,
            parkKey: values.parkKey,
            agenda: values.agenda,
          },
        },
      })
        .then(() => {
          setSubmitting(false);
          setStatus({
            updateEvent: {
              type: 'success',
              message: `Event has updated successfully`,
            },
          });
          onClose();
        })
        .catch((e: ApolloError & Error) => {
          setSubmitting(false);
          setStatus({
            updateEvent: {
              type: 'error',
              message:
                e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message
                  ? e.graphQLErrors[0].message
                  : e.message
                  ? e.message
                  : 'We were unable to update this event, please contact your account manager',
            },
          });
          console.error(e);
        });
    },
    mapPropsToValues: ({ event }): FormValues => {
      return {
        // parkKey: event.parkKey || 'TBC',
        // program: event.program || undefined,
        // status: (event.status && event.status.replace(/ /g, '_')) || 'ACTIVE',
        agenda: event.agenda,
      };
    },
    validationSchema,
  }),
  React.memo,
);

const UpdateEventModalView = ({
  status,
  errors,
  isValid,
  values,
  touched,
  handleChange,
  handleBlur,
  resetForm,
  isSubmitting,
  onClose,
  open,
  updateEventQuery,
}: UpdateEventModalViewProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <Form>
      <DialogTitle id="alert-dialog-title">{'Update Event'}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              select
              id="status"
              label="Status"
              error={!!(errors.status && touched.status)}
              name="status"
              value={values.status}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              style={{ marginTop: '1rem' }}
              disabled={isSubmitting}>
              <MenuItem value="ACTIVE">
                <em>ACTIVE</em>
              </MenuItem>
              {updateEventQuery.statuses &&
                updateEventQuery.statuses.map((status: EventStatus) => (
                  <MenuItem value={status}>{status.replace(/_/g, ' ')}</MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              error={!!(errors.agenda && touched.agenda)}
              label="Agenda"
              name="agenda"
              value={values.agenda}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.agenda && touched.agenda && errors.agenda}
              disabled={isSubmitting}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              error={!!(errors.program && touched.program)}
              label="Program"
              name="program"
              value={values.program}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.program && touched.program && errors.program) || 'Program 1, Program 2 or Special Program'
              }
              disabled={isSubmitting}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              id="parkKey"
              label="Venue"
              error={!!(errors.parkKey && touched.parkKey)}
              name="parkKey"
              value={values.parkKey}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              style={{ marginTop: '1rem' }}
              disabled={isSubmitting}>
              <MenuItem value="TBC">
                <em>TO BE CONFIRMED</em>
              </MenuItem>
              {updateEventQuery.parks &&
                updateEventQuery.parks.map((park: Pick<Park, 'archived' | 'url' | 'title' | 'suburb'>) => (
                  <MenuItem key={park.url} value={park.url} style={{ color: park.archived ? 'gray' : 'black' }}>
                    {park.archived ? `${park.title}, ${park.suburb} - ARCHIVED` : `${park.title}, ${park.suburb}`}
                  </MenuItem>
                ))}
            </TextField>
            {errors.parkKey && touched.parkKey && <FormHelperText>{errors.parkKey}</FormHelperText>}
          </Grid>
        </Grid>
        {map((a: { type: string; message: string }) => (
          <Blockquote key={a.message.substr(0, 6)} color={a.type === 'success' ? 'primary' : 'danger'}>
            <p>{JSON.stringify(a.message)}</p>
          </Blockquote>
        ))(status)}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetForm();
            onClose();
          }}
          color="primary"
          autoFocus>
          Close
        </Button>
        <Button color="primary" type="submit" variant="contained" disabled={!isValid || isSubmitting}>
          Update
        </Button>
      </DialogActions>
    </Form>
  </Dialog>
);

export const UpdateEventModal = enhance(UpdateEventModalView);
