import { ExternalRouteKeys, ExternalRouteLabels, ExternalRoutes, RouteKeys, RouteLabels, Routes } from '@constants';
import { flow, map, pick, toPairs, compact } from 'lodash/fp';
import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { Grid, Typography } from '@material-ui/core';
import { NavBarFooter, NoPrint, UnstyledA, UnstyledLink } from '@queensland-running/qr-components';
import UserContext from 'UserContext';
import { ROLE } from '@utils/role';
import { toRoutes } from '@utils/mapper';

type AppNavProps = {
  navRoutes: string[];
};

const enhance = compose<AppNavProps, {}>(withRouter, React.memo);

export const AppFooterView = () => {
  const currentYear = '2021';
  const { user } = useContext(UserContext);

  const navRoutes = compact([
    RouteKeys.appBase,
    user && user.roles.includes(ROLE.USER_MANAGER) ? RouteKeys.accounts : undefined,
    user && user.roles.includes(ROLE.ADMINISTRATOR_MANAGER) ? RouteKeys.administrators : undefined,
    user && user.roles.includes(ROLE.EVENT_MANAGER) ? RouteKeys.calendar : undefined,
    user && user.roles.includes(ROLE.USER_MANAGER) ? RouteKeys.runners : undefined,
  ]);
  const profileRoutes = [RouteKeys.profile];
  const internalToolLinks = flow(pick([RouteKeys.config]))(Routes);

  const externalToolLinks = flow(
    pick([
      ExternalRouteKeys.aws,
      ExternalRouteKeys.github,
      ExternalRouteKeys.sentry,
      ExternalRouteKeys.sentryAdmin,
      ExternalRouteKeys.seq,
      // ExternalRouteKeys.storybook,
    ]),
  )(ExternalRoutes);

  const toolLinks = {
    ...externalToolLinks,
    ...internalToolLinks,
  };

  return (
    <NoPrint>
      <NavBarFooter
        currentYear={"2022"}
        appTitle="Queensland Running"
        navRoutes={toRoutes(navRoutes, currentYear)}
        authenticatedNavRoutes={toRoutes(profileRoutes)}
        additionalLinks={
          <Grid item xs={6} sm={4} md={3}>
            <Typography variant="h6">Tools</Typography>
            <ul>
              {flow(
                toPairs,
                map(([key, route]) => (
                  <li key={key}>
                    {key === 'config' ? (
                      <UnstyledLink to={route}>
                        <Typography variant="subtitle2">{RouteLabels[key as keyof typeof RouteKeys]}</Typography>
                      </UnstyledLink>
                    ) : (
                      <UnstyledA href={route} target="_blank">
                        <Typography variant="subtitle2">
                          {ExternalRouteLabels[key as keyof typeof ExternalRouteKeys]}
                        </Typography>
                      </UnstyledA>
                    )}
                  </li>
                )),
              )(toolLinks)}
            </ul>
          </Grid>
        }
      />
    </NoPrint>
  );
};

export const AppFooter = enhance(AppFooterView);
