/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchAdministratorsQueryVariables = Types.Exact<{
  input?: Types.Maybe<Types.GetAdministratorsInput>;
}>;

export type FetchAdministratorsQuery = { readonly __typename?: 'Query' } & {
  readonly administrators?: Types.Maybe<
    ReadonlyArray<
      Types.Maybe<
        { readonly __typename?: 'Administrator' } & Pick<
          Types.Administrator,
          'id' | 'nickname' | 'picture' | 'name' | 'firstName' | 'lastName' | 'email' | 'createdAt' | 'lastLogin'
        > & {
            readonly roles?: Types.Maybe<
              ReadonlyArray<{ readonly __typename?: 'Role' } & Pick<Types.Role, 'id' | 'name' | 'description'>>
            >;
          }
      >
    >
  >;
  readonly roles?: Types.Maybe<
    ReadonlyArray<Types.Maybe<{ readonly __typename?: 'Role' } & Pick<Types.Role, 'id' | 'name'>>>
  >;
};

export const FetchAdministratorsDocument = gql`
  query fetchAdministrators($input: GetAdministratorsInput) {
    administrators(input: $input) {
      id
      nickname
      picture
      name
      firstName
      lastName
      email
      createdAt
      lastLogin
      roles {
        id
        name
        description
      }
    }
    roles {
      id
      name
    }
  }
`;
export type FetchAdministratorsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchAdministratorsQuery, FetchAdministratorsQueryVariables>,
  'query'
>;

export const FetchAdministratorsComponent = (props: FetchAdministratorsComponentProps) => (
  <ApolloReactComponents.Query<FetchAdministratorsQuery, FetchAdministratorsQueryVariables>
    query={FetchAdministratorsDocument}
    {...props}
  />
);

export type FetchAdministratorsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchAdministratorsQuery, FetchAdministratorsQueryVariables>;
} &
  TChildProps;
export function withFetchAdministrators<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchAdministratorsQuery,
    FetchAdministratorsQueryVariables,
    FetchAdministratorsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchAdministratorsQuery,
    FetchAdministratorsQueryVariables,
    FetchAdministratorsProps<TChildProps, TDataName>
  >(FetchAdministratorsDocument, {
    alias: 'fetchAdministrators',
    ...operationOptions,
  });
}

/**
 * __useFetchAdministratorsQuery__
 *
 * To run a query within a React component, call `useFetchAdministratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdministratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdministratorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchAdministratorsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAdministratorsQuery, FetchAdministratorsQueryVariables>,
) {
  return Apollo.useQuery<FetchAdministratorsQuery, FetchAdministratorsQueryVariables>(
    FetchAdministratorsDocument,
    baseOptions,
  );
}
export function useFetchAdministratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAdministratorsQuery, FetchAdministratorsQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchAdministratorsQuery, FetchAdministratorsQueryVariables>(
    FetchAdministratorsDocument,
    baseOptions,
  );
}
export type FetchAdministratorsQueryHookResult = ReturnType<typeof useFetchAdministratorsQuery>;
export type FetchAdministratorsLazyQueryHookResult = ReturnType<typeof useFetchAdministratorsLazyQuery>;
export type FetchAdministratorsQueryResult = Apollo.QueryResult<
  FetchAdministratorsQuery,
  FetchAdministratorsQueryVariables
>;
