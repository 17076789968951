import React from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { date, object, string } from 'yup';
import { compose } from 'recompose';
import { useAdminUpdateRunnerMutation } from './mutations.generated';
import { Runner } from '@generated/schema';
import { useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';
import moment from 'moment';
import {
  Modal,
  RunnerMembershipChip,
  RunnerStatusChip,
  DateOfBirthPicker,
  statusColors,
  validation
} from '@queensland-running/qr-components';
import styled from 'styled-components';

type UpdateRunnerProps = {
  runner: Runner;
  open: boolean;
  onClose: () => void;
};
type UpdateRunnerModalViewProps = UpdateRunnerProps;

const enhance = compose<UpdateRunnerModalViewProps, UpdateRunnerProps>(React.memo);

const ChipWrapper = styled.span`
  && {
    > div {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`;

const UpdateRunnerModalView = ({ runner, onClose, open }: UpdateRunnerModalViewProps) => {
  const snackar = useSnackbar();
  const { enqueueSnackbar, closeSnackbar } = snackar
  const [updateRunnerMutation] = useAdminUpdateRunnerMutation({
    onCompleted: ({ runners }) => {
      enqueueSnackbar(`${runners!.adminUpdateRunner!.firstName}'s details have been updated successfully.`, {
        variant: 'success',
      });
    },
    onError: (error: ApolloError) =>
      enqueueSnackbar(error.message, {
        variant: 'error',
        persist: true,
        action: (key) => (
          <Button
            onClick={() => {
              closeSnackbar(key);
            }}>
            Dismiss
          </Button>
        ),
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: runner.firstName || '',
      lastName: runner.lastName || '',
      bibNumber: runner.bibNumber || '',
      gender: runner.gender || '',
      club: runner.club || '',
      dateOfBirth: (runner.dateOfBirth && new Date(runner.dateOfBirth!)) || null,
      membership: runner.membership || '',
    },
    validationSchema: object().shape({
      firstName: validation.qrFirstName.notRequired(),
      lastName: validation.qrLastName.notRequired(),
      bibNumber: string().required('A bib number is required'),
      club: validation.club,
      gender: string().notRequired(),
      dateOfBirth: date()
        .default(undefined)
        .nullable(true)
        .min(moment(new Date(1900, 0, 0)).toDate(), 'Date must be later than 01/01/1900')
        .max(moment(new Date()).toDate(), 'Date must be in the past.')
        // @ts-ignore
        .transform((_, rawValue) => {
          if (rawValue === 'Invalid Date') {
            return null;
          }
          return rawValue && moment(rawValue, ['yyyy-mm-dd']).toDate();
        }),
      membership: string().notRequired(),
    }),
    onSubmit: async (values) => {
      let dob = values.dateOfBirth;

      // if (dob === 'Invalid Date') {
      //   dob = null;
      // }

      await updateRunnerMutation({
        variables: {
          input: {
            id: runner.id,
            firstName: values.firstName,
            lastName: values.lastName,
            club: values.club === '' ? null : values.club,
            bibNumber: values.bibNumber === '' ? null : values.bibNumber,
            dateOfBirth: dob && moment(dob!).format('YYYY-MM-DD'),
            membership: values.membership === '' ? null : values.membership,
          },
        },
      });
      resetForm();
      onClose();
    },
  });

  const { values, errors, touched, handleChange, handleBlur, resetForm, isSubmitting, setFieldValue } = formik;

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={'Update Runner Details'}
      primaryAction={formik.handleSubmit}
      primaryActionText={runner.status === 'PENDING' ? 'Verify' : 'Update'}
      primaryActionColor="primary"
      secondaryAction={onClose}
      secondaryActionText="Close"
      actionDisabled={isSubmitting}
      borderColor={statusColors[runner.status || 'PENDING']}>
      <ChipWrapper>
        <RunnerStatusChip status={runner.status || 'PENDING'} />
        <RunnerMembershipChip membership={runner.membership} />
      </ChipWrapper>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!(errors.firstName && touched.firstName)}
            label="First Name"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.firstName && touched.firstName && errors.firstName}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!(errors.lastName && touched.lastName)}
            label="Last Name"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.lastName && touched.lastName && errors.lastName}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!(errors.bibNumber && touched.bibNumber)}
            label="Bib Number"
            name="bibNumber"
            value={values.bibNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.bibNumber && touched.bibNumber && errors.bibNumber}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            error={!!(errors.membership && touched.membership)}
            label="Membership"
            name="membership"
            value={values.membership}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={(runner.memberships || []).join(',')}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateOfBirthPicker
            error={!!(errors.dateOfBirth && touched.dateOfBirth)}
            label="Date of Birth"
            name="dateOfBirth"
            //@ts-ignore
            value={values.dateOfBirth}
            //@ts-ignore
            onChange={(value) => {
              setFieldValue('dateOfBirth', value);
            }}
            onBlur={handleBlur}
            helperText={errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
            fullWidth
            format="DD/MM/YYYY"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="User Account"
            name="parentId"
            value={runner.parentId}
            disabled={true}
            margin="normal"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            error={!!(errors.club && touched.club)}
            label="Club"
            name="club"
            value={values.club}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.club && touched.club && errors.club}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export const UpdateRunnerModal = enhance(UpdateRunnerModalView);
