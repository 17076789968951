import React from "react";
import {ContactType} from "./types";
import contacts from '@constants/contact'

type ContactMethod<T extends keyof typeof contacts> = keyof Omit<typeof contacts[T], 'name'>

interface ContactProps<T extends keyof typeof contacts> {
  name: T
  type: ContactMethod<T>
}

export const ContactEmail = ({emailAddress, name}: ContactType) => <span>{name} at <a href={`mailto: ${emailAddress}`}>{emailAddress}</a></span>;
export const ContactMobile = ({phoneNumber, name}: ContactType) => <span>{name} on <a href={`tel: ${phoneNumber}`}>{phoneNumber}</a></span>;

export const Contact = <T extends keyof typeof contacts>({type, name}: ContactProps<T>) => {
  const contact: ContactType = contacts[name];

  if (type === 'emailAddress') return <ContactEmail {...contact}/>;
  if (type === 'phoneNumber') return <ContactMobile {...contact}/>;
  return null;
};