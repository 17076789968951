import React, { FunctionComponent, useContext, useState } from 'react';
import { compose } from 'recompose';
import queryString from 'query-string';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FetchCompetitionDocument, FetchCompetitionQuery, useFetchCompetitionQuery } from './queries.generated';
import { flow } from 'lodash/fp';
import {
  Button,
  EventDate,
  EventProgram,
  RunnerDetailsAgeGroups,
  WeeklyCompetitionRegistrationClubs,
  RunnerDetailsGraphOverTime,
  EventRegistrationsTotal,
  EventVenue,
  LoadingComponent,
  NoPrint,
  PageTitle,
  RegisteredRunnersTable,
  RockettRelaysRegistrationTable,
  Results,
  RockettRelaysCompetitionFees,
  RockettRelaysTeamsBreakdown,
  RockettRelaysGraphOverTime,
} from '@queensland-running/qr-components';
import {Grid, Typography} from '@material-ui/core';
import {AllSchoolsChampionships, RockettRelays, RockettRelaysFees, RockettRelaysRegistration, WeeklyCompetition, WinterChampionships} from '@generated/schema';
import { CreateRockettRelayTeamModal } from '@containers/modals/create-rockett-relay-team-modal';
import { ErrorBoundary, withErrorBoundary } from '@components';
import { ROLE } from '@utils/role';
import { CognitoUser } from '@aws-amplify/auth';
import UserContext from '../../../UserContext';
import { UploadCompetitionResultsModal } from '../../modals/upload-competition-results-modal';
import { DeleteCompetitionResultsModal } from '../../modals/delete-competition-results-modal';
import { UpdateRockettRelaysFeesModal } from '@containers/modals/update-rockett-relays-fees-modal';

type QueryParams = {
  competitionId?: string;
  eventId?: string;
};

type LoggedInUser = CognitoUser & { roles: ROLE[] };

const enhance = compose(withErrorBoundary, withRouter, React.memo);

const competitionDetails = (competition: FetchCompetitionQuery['competition'] | undefined) => {
  if (!competition) {
    return <>Unable to load event</>;
  }

  switch (competition.__typename) {
    case 'NoCompetition':
      return <>NO COMPETITION</>;
    case 'WeeklyCompetition':
    case 'AllSchoolsChampionships':
    case 'WinterChampionships':
    case 'RockettRelays':
      const event = competition as WeeklyCompetition | AllSchoolsChampionships | WinterChampionships | RockettRelays;
      const date = new Date(competition.day);

      return (
        <NoPrint>
          <PageTitle title={`${date.getFullYear()} - ${event.agenda}`} hideOnPrint={true} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <EventDate day={date} variant="subtitle1" />
                  <EventProgram program={event.program} variant="subtitle1" />
                  <EventVenue venue={event.venue} variant="subtitle1" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </NoPrint>
      );
  }
};

const registrations = (competition: FetchCompetitionQuery['competition'] | undefined) => {
  if (!competition) return null;
  switch (competition.__typename) {
    case 'WeeklyCompetition':
      return (
        competition.weeklyCompetitionRegistrations && (
          <RegisteredRunnersTable
            //@ts-ignore
            runners={competition.weeklyCompetitionRegistrations.data}
            registrations={true}
            isAdmin={true}
            eventDate={competition.day}
            onClickRegistration={() => {}}
          />
        )
      );
    case 'RockettRelays':
      const rockettRelaysRegistration = competition as RockettRelays
      console.log(rockettRelaysRegistration.rockettRelaysRegistrations)
      return (
        <>
          <Typography variant="h3" color='primary'>One Hour Relay</Typography>
          {rockettRelaysRegistration && (
            <RockettRelaysRegistrationTable
              registrations={(rockettRelaysRegistration.rockettRelaysRegistrations?.data || []).filter(
                (registration) =>
                  registration.teamDetails && registration.teamDetails.relay === 'ONE_HOUR_RELAY',
              )}
              onClickRegistration={() => {}}
            />
          )}
          <br/>
          <Typography variant="h3" color='primary'>Two Hour Relay</Typography>
          {rockettRelaysRegistration && (
            <RockettRelaysRegistrationTable
              registrations={(rockettRelaysRegistration.rockettRelaysRegistrations?.data || []).filter(
                (registration) =>
                  registration.teamDetails && registration.teamDetails.relay === 'TWO_HOUR_RELAY',
              )}
              onClickRegistration={() => {}}
            />
          )}
        </>
      );
    case 'NoCompetition':
    case 'AllSchoolsChampionships':
    case 'WinterChampionships':
      return null;
  }
};

const CompetitionFees: FunctionComponent<{
  competition: FetchCompetitionQuery['competition'] | undefined;
  user?: LoggedInUser;
}> = ({ competition, user }) => {
  const [updateFeesModal, setUpdateFeesModal] = useState<boolean>(false);
  const isEventManager = user && user.roles.includes(ROLE.EVENT_MANAGER);
  if (!competition) {
    return <>Unable to load event</>;
  }

  switch (competition.__typename) {
    case 'NoCompetition':
    case 'WeeklyCompetition':
    case 'AllSchoolsChampionships':
    case 'WinterChampionships':
      return null;
    case 'RockettRelays':
      return (
        <>
          <RockettRelaysCompetitionFees
            fees={competition.fees as RockettRelaysFees}
            onClickUpdateFees={isEventManager ? () => setUpdateFeesModal(true) : undefined}
          />
          {updateFeesModal && (
            <UpdateRockettRelaysFeesModal
              open={updateFeesModal}
              onClose={() => setUpdateFeesModal(false)}
              //@ts-ignore
              competition={competition}
            />
          )}
        </>
      );
  }

  return null;
};

const Register: FunctionComponent<{
  competition: FetchCompetitionQuery['competition'] | undefined;
}> = ({ competition }) => {
  const [registerRockettRelayTeam, setRegisterRockettRelayTeam] = useState<boolean>(false);

  if (!competition) {
    return <>Unable to load event</>;
  }

  switch (competition.__typename) {
    case 'NoCompetition':
    case 'WeeklyCompetition':
    case 'AllSchoolsChampionships':
    case 'WinterChampionships':
      return null;
    case 'RockettRelays':
      const competitionId = competition.id;
      return (
        <>
          <Button onClick={() => setRegisterRockettRelayTeam(true)} title={'Register Team'} />
          {registerRockettRelayTeam && (
            <CreateRockettRelayTeamModal
              eventId={competition.id}
              eventDate={competition.day}
              open={registerRockettRelayTeam}
              //@ts-ignore
              fees={competition.fees}
              onClose={() => setRegisterRockettRelayTeam(false)}
              onCreateTeam={(cache, { data }) => {
                // Fetch from the cache
                const existingItems = cache.readQuery<FetchCompetitionQuery>({
                  query: FetchCompetitionDocument,
                  variables: {
                    id: competitionId,
                  },
                });

                const newItem = data!.competition!.registerTeamForRockettRelays;
                // @ts-ignore
                const cacheObject: object = existingItems;

                // @ts-ignore
                const existingTeams = [...existingItems.competition.rockettRelaysRegistrations.data];

                cache.writeQuery<FetchCompetitionQuery>({
                  query: FetchCompetitionDocument,
                  variables: {
                    id: competitionId,
                  },
                  data: {
                    ...cacheObject,
                    competition: {
                      //@ts-ignore
                      ...cacheObject.competition,
                      registeredTeams: {
                        data: [...existingTeams, newItem],
                      },
                    },
                  },
                });
              }}
            />
          )}
        </>
      );
  }

  return null;
};

const CompetitionResults: FunctionComponent<{
  competition: FetchCompetitionQuery['competition'] | undefined;
  user?: LoggedInUser;
}> = ({ competition, user }) => {
  const [uploadResultsModalOpen, setUploadResultsModalOpen] = useState(false);
  const [deleteResultsModalOpen, setDeleteResultsModalOpen] = useState(false);

  if (!competition) {
    return <>Unable to load event</>;
  }

  const isResultsManager = user && user.roles.includes(ROLE.RESULTS_MANAGER);
  switch (competition.__typename) {
    case 'NoCompetition':
      return null;
    case 'WeeklyCompetition':
      return (
        <>
          {uploadResultsModalOpen && (
            <UploadCompetitionResultsModal
              competitionId={competition.id}
              open={uploadResultsModalOpen}
              onClose={() => setUploadResultsModalOpen(false)}
            />
          )}
          {deleteResultsModalOpen && (
            <DeleteCompetitionResultsModal
              competitionId={competition.id}
              open={deleteResultsModalOpen}
              onClose={() => setDeleteResultsModalOpen(false)}
            />
          )}
          <Results
            competition={competition}
            onClickUploadResults={
              isResultsManager && competition.status !== 'CANCELLED' ? () => setUploadResultsModalOpen(true) : undefined
            }
            onClickDeleteResults={
              isResultsManager && competition.status !== 'CANCELLED' ? () => setDeleteResultsModalOpen(true) : undefined
            }
          />
        </>
      );
    case 'AllSchoolsChampionships':
    case 'WinterChampionships':
    case 'RockettRelays':
      return null;
  }
  return null;
};

const CompetitionDataVisualisation: FunctionComponent<{
  competition: FetchCompetitionQuery['competition'] | undefined;
  user?: LoggedInUser;
}> = ({ competition, user }) => {
  const isEventManager = user && user.roles.includes(ROLE.EVENT_MANAGER);
  if (!isEventManager) return null;

  if (!competition) {
    return <>Unable to load event</>;
  }

  switch (competition.__typename) {
    case 'NoCompetition':
      return null;
    case 'WeeklyCompetition':
      const registeredAthletes = competition.weeklyCompetitionRegistrations;
      return (
        <>
          {registeredAthletes && (
            <ErrorBoundary>
              <EventRegistrationsTotal
                //@ts-ignore
                registrations={registeredAthletes}
              />
            </ErrorBoundary>
          )}
          <br />

          {registeredAthletes && (
            <ErrorBoundary>
              <RunnerDetailsGraphOverTime
                //@ts-ignore
                registrations={registeredAthletes}
              />
            </ErrorBoundary>
          )}
          <br />

          {registeredAthletes && (
            <ErrorBoundary>
              <RunnerDetailsAgeGroups
                //@ts-ignore
                registrations={registeredAthletes}
              />
            </ErrorBoundary>
          )}
          <br />
          {registeredAthletes && (
            <ErrorBoundary>
              <WeeklyCompetitionRegistrationClubs
                //@ts-ignore
                registrations={registeredAthletes}
              />
            </ErrorBoundary>
          )}
        </>
      );
    case 'RockettRelays':
      const registeredTeams = (competition as RockettRelays).rockettRelaysRegistrations;
      return (
        <>
          {registeredTeams && (
            <ErrorBoundary>
              <RockettRelaysTeamsBreakdown registrations={registeredTeams} />
            </ErrorBoundary>
          )}
          <br />
          {registeredTeams && (
            <ErrorBoundary>
              <RockettRelaysGraphOverTime registrations={registeredTeams} />
            </ErrorBoundary>
          )}
        </>
      );
    case 'AllSchoolsChampionships':
    case 'WinterChampionships':
      return null;
  }
  return null;
};

const CompetitionView = ({ location: { search } }: RouteComponentProps) => {
  const { user } = useContext(UserContext);
  const { data, loading } = useFetchCompetitionQuery({
    errorPolicy: 'all',
    variables: {
      id: flow(queryString.parse, ({ eventId, competitionId }: QueryParams) => competitionId || eventId)(search),
    },
  });

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      {competitionDetails(data && data.competition)}
      <Grid container spacing={3}>
        {/*<Grid item xs={12} sm={8}>*/}
          {/*{registrations(data && data.competition)}*/}
          {/*<br />*/}
          {/*<Register competition={data && data.competition} />*/}
        {/*</Grid>*/}
        <Grid item xs={12} sm={6} md={4}>
          <NoPrint>
            <CompetitionResults competition={data && data.competition} user={user} />
            <br />
            <CompetitionFees competition={data && data.competition} user={user} />
            <br />
            {/*<br />*/}
            {/*<CompetitionDataVisualisation competition={data && data.competition} user={user} />*/}
          </NoPrint>
        </Grid>
      </Grid>
    </>
  );
};

// @ts-ignore
export const CompetitionPage = enhance(CompetitionView);
