/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DownloadRunnersMutationVariables = Types.Exact<{
  input: Types.DownloadFileInput;
}>;

export type DownloadRunnersMutation = { readonly __typename?: 'Mutation' } & {
  readonly runners?: Types.Maybe<
    { readonly __typename?: 'RunnersMutation' } & {
      readonly download?: Types.Maybe<
        { readonly __typename?: 'DownloadFileResponse' } & Pick<
          Types.DownloadFileResponse,
          'success' | 'fileName' | 'fileDownloadUrl'
        >
      >;
    }
  >;
};

export const DownloadRunnersDocument = gql`
  mutation downloadRunners($input: DownloadFileInput!) {
    runners {
      download(input: $input) {
        success
        fileName
        fileDownloadUrl
      }
    }
  }
`;
export type DownloadRunnersMutationFn = Apollo.MutationFunction<
  DownloadRunnersMutation,
  DownloadRunnersMutationVariables
>;
export type DownloadRunnersComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DownloadRunnersMutation, DownloadRunnersMutationVariables>,
  'mutation'
>;

export const DownloadRunnersComponent = (props: DownloadRunnersComponentProps) => (
  <ApolloReactComponents.Mutation<DownloadRunnersMutation, DownloadRunnersMutationVariables>
    mutation={DownloadRunnersDocument}
    {...props}
  />
);

export type DownloadRunnersProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<DownloadRunnersMutation, DownloadRunnersMutationVariables>;
} &
  TChildProps;
export function withDownloadRunners<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DownloadRunnersMutation,
    DownloadRunnersMutationVariables,
    DownloadRunnersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DownloadRunnersMutation,
    DownloadRunnersMutationVariables,
    DownloadRunnersProps<TChildProps, TDataName>
  >(DownloadRunnersDocument, {
    alias: 'downloadRunners',
    ...operationOptions,
  });
}

/**
 * __useDownloadRunnersMutation__
 *
 * To run a mutation, you first call `useDownloadRunnersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadRunnersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadRunnersMutation, { data, loading, error }] = useDownloadRunnersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadRunnersMutation(
  baseOptions?: Apollo.MutationHookOptions<DownloadRunnersMutation, DownloadRunnersMutationVariables>,
) {
  return Apollo.useMutation<DownloadRunnersMutation, DownloadRunnersMutationVariables>(
    DownloadRunnersDocument,
    baseOptions,
  );
}
export type DownloadRunnersMutationHookResult = ReturnType<typeof useDownloadRunnersMutation>;
export type DownloadRunnersMutationResult = Apollo.MutationResult<DownloadRunnersMutation>;
export type DownloadRunnersMutationOptions = Apollo.BaseMutationOptions<
  DownloadRunnersMutation,
  DownloadRunnersMutationVariables
>;
