/* eslint-disable */
import * as Types from '../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchUpdateEventQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchUpdateEventQuery = { readonly __typename?: 'Query' } & Pick<Types.Query, 'statuses'> & {
    readonly parks?: Types.Maybe<
      ReadonlyArray<
        { readonly __typename?: 'Park' } & Pick<
          Types.Park,
          'url' | 'title' | 'suburb' | 'imageURL' | 'street' | 'archived'
        >
      >
    >;
  };

export const FetchUpdateEventDocument = gql`
  query fetchUpdateEvent {
    parks(showAll: true) {
      url
      title
      suburb
      imageURL
      street
      archived
    }
    statuses
  }
`;
export type FetchUpdateEventComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchUpdateEventQuery, FetchUpdateEventQueryVariables>,
  'query'
>;

export const FetchUpdateEventComponent = (props: FetchUpdateEventComponentProps) => (
  <ApolloReactComponents.Query<FetchUpdateEventQuery, FetchUpdateEventQueryVariables>
    query={FetchUpdateEventDocument}
    {...props}
  />
);

export type FetchUpdateEventProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchUpdateEventQuery, FetchUpdateEventQueryVariables>;
} &
  TChildProps;
export function withFetchUpdateEvent<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchUpdateEventQuery,
    FetchUpdateEventQueryVariables,
    FetchUpdateEventProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchUpdateEventQuery,
    FetchUpdateEventQueryVariables,
    FetchUpdateEventProps<TChildProps, TDataName>
  >(FetchUpdateEventDocument, {
    alias: 'fetchUpdateEvent',
    ...operationOptions,
  });
}

/**
 * __useFetchUpdateEventQuery__
 *
 * To run a query within a React component, call `useFetchUpdateEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUpdateEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUpdateEventQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUpdateEventQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchUpdateEventQuery, FetchUpdateEventQueryVariables>,
) {
  return Apollo.useQuery<FetchUpdateEventQuery, FetchUpdateEventQueryVariables>(FetchUpdateEventDocument, baseOptions);
}
export function useFetchUpdateEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchUpdateEventQuery, FetchUpdateEventQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchUpdateEventQuery, FetchUpdateEventQueryVariables>(
    FetchUpdateEventDocument,
    baseOptions,
  );
}
export type FetchUpdateEventQueryHookResult = ReturnType<typeof useFetchUpdateEventQuery>;
export type FetchUpdateEventLazyQueryHookResult = ReturnType<typeof useFetchUpdateEventLazyQuery>;
export type FetchUpdateEventQueryResult = Apollo.QueryResult<FetchUpdateEventQuery, FetchUpdateEventQueryVariables>;
