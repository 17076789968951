import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import Amplify from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import { env } from './@constants';
import AuthApp from './AuthApp';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn: env.sentry.dsn,
  integrations: [new Integrations.BrowserTracing()],
  environment: env.stage,
  tracesSampleRate: 0.2,
});

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found; App needs an entry point!');
}

Amplify.configure({
  Auth: env.aws,
});

if (rootElement.hasChildNodes()) {
  hydrate(<AuthApp />, rootElement);
} else {
  render(<AuthApp />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
