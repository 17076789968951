import React from 'react';
import { ROLE } from './@utils/role';
import { CognitoUser } from '@aws-amplify/auth';

const AuthContext = React.createContext<{ user?: CognitoUser & { roles: ROLE[] }; isLoaded: boolean, updateCurrentUser: (user?: CognitoUser) => void }>({
  user: undefined,
  isLoaded: false,
  updateCurrentUser: () => {}
});
export default AuthContext;
