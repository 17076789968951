/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchAccountsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchAccountsQuery = { readonly __typename?: 'Query' } & {
  readonly accounts?: Types.Maybe<
    ReadonlyArray<
      Types.Maybe<
        { readonly __typename?: 'UserAccount' } & Pick<
          Types.UserAccount,
          | 'id'
          | 'firstName'
          | 'lastName'
          | 'email'
          | 'address'
          | 'phoneNumber'
          | 'postalAddress'
          | 'postcode'
          | 'suburb'
          | 'status'
          | 'created'
          | 'updated'
        >
      >
    >
  >;
};

export const FetchAccountsDocument = gql`
  query fetchAccounts {
    accounts {
      id
      firstName
      lastName
      email
      address
      phoneNumber
      postalAddress
      postcode
      suburb
      status
      created
      updated
    }
  }
`;
export type FetchAccountsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchAccountsQuery, FetchAccountsQueryVariables>,
  'query'
>;

export const FetchAccountsComponent = (props: FetchAccountsComponentProps) => (
  <ApolloReactComponents.Query<FetchAccountsQuery, FetchAccountsQueryVariables>
    query={FetchAccountsDocument}
    {...props}
  />
);

export type FetchAccountsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchAccountsQuery, FetchAccountsQueryVariables>;
} &
  TChildProps;
export function withFetchAccounts<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchAccountsQuery,
    FetchAccountsQueryVariables,
    FetchAccountsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchAccountsQuery,
    FetchAccountsQueryVariables,
    FetchAccountsProps<TChildProps, TDataName>
  >(FetchAccountsDocument, {
    alias: 'fetchAccounts',
    ...operationOptions,
  });
}

/**
 * __useFetchAccountsQuery__
 *
 * To run a query within a React component, call `useFetchAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAccountsQuery, FetchAccountsQueryVariables>,
) {
  return Apollo.useQuery<FetchAccountsQuery, FetchAccountsQueryVariables>(FetchAccountsDocument, baseOptions);
}
export function useFetchAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAccountsQuery, FetchAccountsQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchAccountsQuery, FetchAccountsQueryVariables>(FetchAccountsDocument, baseOptions);
}
export type FetchAccountsQueryHookResult = ReturnType<typeof useFetchAccountsQuery>;
export type FetchAccountsLazyQueryHookResult = ReturnType<typeof useFetchAccountsLazyQuery>;
export type FetchAccountsQueryResult = Apollo.QueryResult<FetchAccountsQuery, FetchAccountsQueryVariables>;
