import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Form, FormikProps, withFormik } from 'formik';
import { FileUpload } from '@queensland-running/qr-components';
import { Competition } from '@generated/schema';
import { InferType, mixed, object, string } from 'yup';
import { compose } from 'recompose';
import {
  UploadResultsProps as UploadResultsMutationProps,
  withUploadResults as withUploadResultsMutation,
} from './mutations.generated';
import { handleFileUpload } from '@utils/fileUpload';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { ApolloError } from '@apollo/client';

const suggestedName = (id: Competition['id']) => `Queensland-Running-${id}-Results.pdf`;

const validationSchema = object().shape({
  file: mixed()
    // .test('fileSize', 'File Size is too large', (value) => value && value.size <= 500000)
    // .test('fileType', 'Unsupported File Format', (value) => value && ['application/pdf'].includes(value.type))
    .notRequired(),
  fileName: string()
    .matches(
      /^[-a-zA-Z0-9]+[^-_].pdf$/,
      'Filename can only contain letters, numbers and hyphens, and must end with .pdf',
    )
    .notRequired(),
  url: string()
    .url('Must be a valid url, including http:// or https://')
    .notRequired(),
});

type UploadResultsProps = { eventId: string; open: boolean; onClose: () => void };
type FormValues = InferType<typeof validationSchema>;
type UploadResultsModalViewProps = UploadResultsProps & FormikProps<FormValues>;

const enhance = compose<UploadResultsModalViewProps, UploadResultsProps>(
  withSnackbar,
  withUploadResultsMutation<UploadResultsMutationProps & WithSnackbarProps>({
    alias: 'withUploadResultsMutation',
    name: 'uploadResults',
    options: ({ enqueueSnackbar, closeSnackbar }) => ({
      onError: (error: ApolloError) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <Button
              onClick={() => {
                closeSnackbar(key);
              }}>
              Dismiss
            </Button>
          ),
        });
      },
    }),
  }),
  withFormik<{ uploadResults: any } & UploadResultsMutationProps & UploadResultsProps & WithSnackbarProps, FormValues>({
    handleSubmit: async (values, { setStatus, props: { eventId, uploadResults, onClose, enqueueSnackbar } }) => {
      setStatus(null);

      const result = await uploadResults({
        variables: {
          input: {
            eventId,
            fileName: values.fileName,
            url: values.url,
          },
        },
      });

      const response = result.data.event.uploadResults;
      if (response.success && response.method === 'UPLOAD') {
        await handleFileUpload(response.fileUploadUrl, values.file);
      }

      enqueueSnackbar(`Results have been added for ${eventId}`, { variant: 'success' });
      onClose();
    },
    mapPropsToValues: (): FormValues => {
      // @ts-ignore
      return {
        fileName: '',
        file: undefined,
        url: '',
      };
    },
    validationSchema,
  }),
  React.memo,
);

const UploadResultsModalView = ({
  eventId,
  errors,
  isValid,
  values,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  isSubmitting,
  onClose,
  open,
}: UploadResultsModalViewProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <Form>
      <DialogTitle id="alert-dialog-title">{'Upload Results'}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Typography>
          Uploading a new results file will overwrite any existing file. At this stage, only one file can be saved for
          each event. Results can either be; a PDF stored in Queensland Running's results storage (S3), or a URL to
          externally hosted results.
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FileUpload
              initialFiles={[]}
              onChange={(files: FileList) => {
                setFieldValue('file', files[0]);
                setFieldValue('fileName', suggestedName(eventId));
                return files;
              }}
              onDelete={(file: any) => {
                setFieldValue('file', []);
                return file;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={!!(errors.fileName && touched.fileName)}
              label="File name"
              name="fileName"
              value={values.fileName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={suggestedName(eventId)}
              helperText={
                (errors.fileName && touched.fileName && errors.fileName) ||
                `The download url will be: <S3_BUCKET>/${eventId.substr(0, 4)}/${values.fileName || '<FILENAME>'}`
              }
              disabled={false}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography>
              <b>OR</b>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              error={!!(errors.url && touched.url)}
              label="URL to results"
              name="url"
              value={values.url}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                (errors.url && touched.url && errors.url) ||
                'Link to results hosted by a third-party, such as https://www.webscorer.com/qldrun'
              }
              disabled={false}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFieldValue('fileName', '');
            setFieldValue('file', undefined);
            setFieldValue('url', '');
            onClose();
          }}
          color="primary"
          autoFocus>
          Close
        </Button>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={
            !isValid ||
            isSubmitting ||
            !(values.url || (values.fileName && values.file)) ||
            (values.url && (values.fileName || values.file))
          }>
          Upload Results
        </Button>
      </DialogActions>
    </Form>
  </Dialog>
);

export const UploadResultsModal = enhance(UploadResultsModalView);
