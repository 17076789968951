import React from 'react';
import { Button, Typography, Grid } from '@material-ui/core';
import { Modal, BibNumber } from '@queensland-running/qr-components';
import { Event as EventType, Runner } from '@generated/schema';
import { compose } from 'recompose';
import { useSnackbar, withSnackbar } from 'notistack';
import { useDeleteRunnerMutation } from './mutations.generated';
import { ChevronRight } from '@material-ui/icons';

type DeleteRunnerModalViewProps = DeleteRunnerProps & {
  event: EventType;
};

type DeleteRunnerProps = { open: boolean; onClose: () => void; runner: Runner };

const enhance = compose<DeleteRunnerModalViewProps, DeleteRunnerProps>(withSnackbar, React.memo);

const DeleteRunnerModalView = ({ onClose, open, runner }: DeleteRunnerModalViewProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteRunner] = useDeleteRunnerMutation({
    variables: {
      input: {
        id: runner.id,
      },
    },
    onCompleted: () => {
      enqueueSnackbar(`Runner is marked for deletion.`, { variant: 'success' });
      onClose();
    },
    onError: (e) => {
      enqueueSnackbar(e.message, {
        variant: 'error',
        persist: true,
        action: (key) => <Button onClick={() => closeSnackbar(key)}>Dismiss</Button>,
      });
      onClose();
    },
  });

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={'Delete Runner'}
      primaryAction={deleteRunner}
      primaryActionText="Yes, Delete Runner"
      primaryActionColor="danger"
      secondaryAction={onClose}
      secondaryActionText="No">
      <Typography>Are you sure you want to delete this runner?</Typography>
      <br />
      <Grid container direction="row" justify="space-between">
          {runner.bibNumber && (
            <BibNumber
              bibNumber={parseInt(runner.bibNumber)}
              firstName={runner.firstName || ''}
              lastName={runner.lastName || ''}
            />
          )}
        <ChevronRight style={{ fontSize: '7.5rem' }}/>
          {runner.bibNumber && (
            <BibNumber
              bibNumber={parseInt(runner.bibNumber)}
              firstName={runner.firstName || ''}
              lastName={runner.lastName || ''}
              deleted
            />
          )}
        </Grid>
    </Modal>
  );
};

export const DeleteRunnerModal = enhance(DeleteRunnerModalView);
