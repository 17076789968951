export const handleFileUpload = (uploadUrl: string, file: File) =>
  fetch(uploadUrl, {
    method: 'PUT',
    body: file,

    // headers: {
    //   'Content-Type': file.type,
    //   'Content-Length': file.size.toString(),
    // },
  });
