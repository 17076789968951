import React from 'react';
import { branch, compose, renderComponent, withStateHandlers } from 'recompose';
import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Administrator, Role } from '@generated/schema';
import { FetchAdministratorQueryVariables, withFetchAdministrator } from './queries.generated';
import { Person } from '@material-ui/icons';
import { RouteKeys, RouteLabels } from '@constants';
import styled from 'styled-components';
import {ButtonWrapper, formatFullDate, LoadingComponent, FieldValue, PageTitle, FieldCustomValue} from '@queensland-running/qr-components';
import { UpdateAdministratorModal } from '@containers/modals/update-administrator-modal';
import { ChangePasswordModal } from '../../modals/change-password-modal';
import {DataValue} from "@apollo/client/react/hoc";

type RegisterProps = {};

type AdministratorData = {
  administrator: Administrator;
};

type AdministratorQueryProps = {
  administratorQuery: NonNullable<DataValue<AdministratorData, FetchAdministratorQueryVariables>>;
};

type AdministratorViewProps = AdministratorViewState & AdministratorViewUpdaters & AdministratorQueryProps;

type AdministratorViewState = {
  updateAdministratorModalOpen: boolean;
  changePasswordModalOpen: boolean;
};

type AdministratorViewUpdaters = {
  openUpdateAdministratorModal: any; //StateHandler<Pick<AdministratorViewState, 'updateAdministratorModalOpen'>>(event: React.ChangeEvent<{ value: unknown }>) => AdministratorViewState;
  closeUpdateAdministratorModal: any; //StateHandler<Pick<AdministratorViewState, 'updateAdministratorModalOpen'>>(event: React.ChangeEvent<{ value: unknown }>) => AdministratorViewState;
  openChangePasswordModal: any; //StateHandler<Pick<AdministratorViewState, 'updateAdministratorModalOpen'>>(event: React.ChangeEvent<{ value: unknown }>) => AdministratorViewState;
  closeChangePasswordModal: any; //StateHandler<Pick<AdministratorViewState, 'updateAdministratorModalOpen'>>(event: React.ChangeEvent<{ value: unknown }>) => AdministratorViewState;
};

type AdministratorState = {
  updateAdministratorModalOpen: boolean;
  changePasswordModalOpen: boolean;
};

const enhance = compose<AdministratorViewProps, RegisterProps>(
  withRouter,
  withFetchAdministrator({
    name: 'administratorQuery',
  }),
  withStateHandlers(
    {
      updateAdministratorModalOpen: false,
      changePasswordModalOpen: false,
    },
    {
      openUpdateAdministratorModal: () => (): Partial<AdministratorState> => ({
        updateAdministratorModalOpen: true,
      }),
      closeUpdateAdministratorModal: () => (): Partial<AdministratorState> => ({
        updateAdministratorModalOpen: false,
      }),
      openChangePasswordModal: () => (): Partial<AdministratorState> => ({
        changePasswordModalOpen: true,
      }),
      closeChangePasswordModal: () => (): Partial<AdministratorState> => ({
        changePasswordModalOpen: false,
      }),
    },
  ),
  branch<AdministratorQueryProps>(
    ({ administratorQuery: { loading } }: AdministratorQueryProps) => loading,
    renderComponent(() => <LoadingComponent />),
  ),
  React.memo,
);

const StyledAvatar = styled(Avatar)`
&& {
  color: ${({ theme }) => theme.palette.getContrastText(theme.palette.secondary.main)}
  background-color: ${({ theme }) => theme.palette.secondary.main}
  }
`;

const R = ({ name, description }: Role) => (
  <li key={name || ''}>
    {name}
    <br />
    <small>{description}</small>
  </li>
);

const Roles = styled.ul`
  li:not(:last-child) {
    margin-bottom: 5px;
  }
  margin-top: 0;
  padding-left: 20px;
`;

const AdministratorView = ({
  administratorQuery: { administrator },
  updateAdministratorModalOpen,
  openUpdateAdministratorModal,
  closeUpdateAdministratorModal,
  changePasswordModalOpen,
  openChangePasswordModal,
  closeChangePasswordModal,
}: AdministratorViewProps) => {
  if (!administrator) {
    return null;
  }
  const { id, roles, createdAt, lastLogin, lastName, firstName, email } = administrator;
  return (
    <>
      <PageTitle title={RouteLabels[RouteKeys.profile]}/>
      <Grid container wrap="nowrap" spacing={3}>
        <Grid item>
          <StyledAvatar style={{ width: '6rem', height: '6rem' }}>
            <Person />
          </StyledAvatar>
        </Grid>
        <Grid item xs zeroMinWidth>
          <Typography variant={'h2'} noWrap>
            {firstName} {lastName}
          </Typography>
          <br />
          <Typography variant={'subtitle1'}>
            <b>ID:</b>&nbsp;{id}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FieldValue label="Email" name="email" value={email} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="First Name" name="firstName" value={firstName} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="Last Name" name="lastName" value={lastName} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FieldValue label="Signed up" name="signedUp" value={createdAt && formatFullDate(createdAt)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="Latest Login" name="latestLogin" value={lastLogin && formatFullDate(lastLogin)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldCustomValue label="Access" name="access" CustomValue={() => <Roles>{roles && roles.map(R)}</Roles>} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldCustomValue
            label="Actions"
            name="access"
            CustomValue={() =>
              <ButtonWrapper>
                <Button
                  disabled={true}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={openUpdateAdministratorModal}>
                  Change details
                </Button>
                <Button
                  disabled={true}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={openChangePasswordModal}>
                  Change password
                </Button>
              </ButtonWrapper>
            }
          />
        </Grid>
      </Grid>
      <UpdateAdministratorModal
        user={administrator}
        open={updateAdministratorModalOpen}
        onClose={closeUpdateAdministratorModal}
      />
      <ChangePasswordModal open={changePasswordModalOpen} onClose={closeChangePasswordModal} />
    </>
  );
};

export const Profile = enhance(AdministratorView);
