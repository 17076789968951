import React, { Fragment } from 'react';
import { map } from 'lodash/fp';
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { compose } from 'recompose';
import { UserAccount } from '@generated/schema';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { formatDateTime, StyledIcon } from '@queensland-running/qr-components';
import {DirectionsRun} from "@material-ui/icons";

type UserAccountTableProps = {
  accounts: UserAccount[];
  onEditAccount: (account: UserAccount) => void;
  onNavigateToRunners: (id: string) => void;
};

type UserAccountTableViewProps = UserAccountTableProps & RouteComponentProps;

const enhance = compose<UserAccountTableViewProps, UserAccountTableProps>(withRouter, React.memo);

export const UserAccountTableView = ({ accounts, onNavigateToRunners }: UserAccountTableViewProps) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>User</TableCell>
        <TableCell>Created/Updated</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Contact Number</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {map((currentUser: UserAccount) => {
        const { firstName, lastName, email, phoneNumber, id, status, created, updated } = currentUser;

        return (
          <Fragment key={id}>
            <TableRow>
              <TableCell>
                <b>
                  {firstName} {lastName}
                </b>
                <br />
                <small>{email}</small>
              </TableCell>
              <TableCell>
                {created ? formatDateTime(created) : 'MISSING'}
                <br />
                {updated ? formatDateTime(updated) : 'MISSING'}
              </TableCell>
              <TableCell>{status}</TableCell>
              <TableCell>{phoneNumber}</TableCell>
              <TableCell>
                {status !== 'UNCONFIRMED' && (
                  <Tooltip title="Runners" placement="left">
                    <StyledIcon
                      component={DirectionsRun}
                      color="primary"
                      iconColor="secondary"
                      onClick={() => onNavigateToRunners(id)}
                    />
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          </Fragment>
        );
      })(accounts)}
    </TableBody>
  </Table>
);

export const UserAccountTable = enhance(UserAccountTableView);
