import React from 'react';
import {
  Button,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import {  Modal } from '@queensland-running/qr-components';
import { compose } from 'recompose';
import { useDeleteCompetitionResultsMutation } from './mutations.generated';
import { useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';

type DeleteResultsProps = { competitionId: string; open: boolean; onClose: () => void };

const enhance = compose<DeleteResultsProps, DeleteResultsProps>(React.memo);

const DeleteCompetitionResultsModalView = ({ competitionId, onClose, open }: DeleteResultsProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteResults] = useDeleteCompetitionResultsMutation({
    onCompleted: (data) => {
      if (data) {
        enqueueSnackbar(`Results have been successfully removed for ${competitionId}`, { variant: 'success' });
        onClose();
      }
    },
    onError: (error: ApolloError) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        persist: true,
        action: (key) => (
          <Button
            onClick={() => {
              closeSnackbar(key);
            }}>
            Dismiss
          </Button>
        ),
      });
    },
  });

  const { isSubmitting, handleSubmit } = useFormik({
    onSubmit: async () => {
      await deleteResults({
        variables: {
          input: {
            competitionId: competitionId,
          },
        },
      });
    },
    initialValues: {
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={'Delete Results'}
      primaryAction={handleSubmit}
      primaryActionText={'Yes, Delete Results'}
      primaryActionColor="danger"
      secondaryAction={onClose}
      secondaryActionText="No"
      actionDisabled={isSubmitting}>
      <Typography>Are you sure you want to delete these results?</Typography>
    </Modal>
  );
};

export const DeleteCompetitionResultsModal = enhance(DeleteCompetitionResultsModalView);
