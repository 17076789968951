// @ts-nocheck
import React, {useContext, useState} from 'react';
import {Avatar} from '@material-ui/core';
import {compose} from 'recompose';
import {RouteKeys} from '@constants';
import styled from 'styled-components';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {compact} from 'lodash/fp';
import {NavBarHeader, NoPrint} from '@queensland-running/qr-components';
import UserContext from 'UserContext';
import {Auth} from '@aws-amplify/auth';
import {ROLE} from '@utils/role';
import {toRoutes} from "@utils/mapper";

type AppNavViewProps = RouteComponentProps & {
  current: string;
  active: number;
  handleClick: () => void;
};

const StyledAvatar = styled(Avatar)`
  && {
    color: ${({theme}) => theme.palette.getContrastText(theme.palette.secondary.main)}
    background-color: ${({theme}) => theme.palette.secondary.main}
  }
`;

const enhance = compose<AppNavViewProps, {}>(withRouter);

const AppNavView = ({location: {pathname}}: AppNavViewProps) => {
  const {user} = useContext(UserContext);
  const currentYear = '2021'

  const current = pathname;
  const navRoutes = user && compact([
    user.roles.includes(ROLE.USER_MANAGER) ? RouteKeys.accounts : undefined,
    user.roles.includes(ROLE.ADMINISTRATOR_MANAGER) ? RouteKeys.administrators : undefined,
    user.roles.includes(ROLE.EVENT_MANAGER) ? RouteKeys.calendar : undefined,
    user.roles.includes(ROLE.USER_MANAGER) ? RouteKeys.runners : undefined,
  ]) || [];

  const profileRoutes = compact([
    RouteKeys.profile
  ]);

  return (
    <NoPrint>
      <NavBarHeader
        appTitle="Queensland Running Admin"
        current={current}
        isAuthenticated={!!user}
        navRoutes={toRoutes(navRoutes, currentYear)}
        authenticatedNavRoutes={toRoutes(profileRoutes)}
        onLogout={() => {
          Auth.signOut();
        }}
      />
    </NoPrint>
  );
};

export const AppNav = enhance(AppNavView);
