import React from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { FileUpload, Modal } from '@queensland-running/qr-components';
import { mixed, object, string } from 'yup';
import { compose } from 'recompose';
import { useUploadCompetitionResultsMutation } from './mutations.generated';
import { handleFileUpload } from '@utils/fileUpload';
import { useSnackbar } from 'notistack';
import { ApolloError } from '@apollo/client';

const suggestedName = (id: string) => `Queensland-Running-${id}-Results.pdf`;

const validationSchema = object().shape({
  file: mixed()
    // .test('fileSize', 'File Size is too large', (value) => value && value.size <= 500000)
    // .test('fileType', 'Unsupported File Format', (value) => value && ['application/pdf'].includes(value.type))
    .notRequired(),
  fileName: string()
    .matches(
      /^[-a-zA-Z0-9]+[^-_].pdf$/,
      'Filename can only contain letters, numbers and hyphens, and must end with .pdf',
    )
    .notRequired(),
  url: string()
    .url('Must be a valid url, including http:// or https://')
    .notRequired(),
});

type UploadResultsProps = { competitionId: string; open: boolean; onClose: () => void };

const enhance = compose<UploadResultsProps, UploadResultsProps>(React.memo);

const UploadCompetitionResultsModalView = ({ competitionId, onClose, open }: UploadResultsProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [uploadResults] = useUploadCompetitionResultsMutation({
    onError: (error: ApolloError) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        persist: true,
        action: (key) => (
          <Button
            onClick={() => {
              closeSnackbar(key);
            }}>
            Dismiss
          </Button>
        ),
      });
    },
  });

  const { errors, values, touched, handleChange, handleBlur, setFieldValue, isSubmitting, handleSubmit } = useFormik({
    onSubmit: async (values) => {
      const result = await uploadResults({
        variables: {
          input: {
            competitionId: competitionId,
            fileName: values.fileName,
            url: values.url,
          },
        },
      });

      if (result.data) {
        const response = result.data.competition && result.data.competition.uploadCompetitionResults;
        if (response) {
          if (response.success && response.method === 'UPLOAD' && response.fileUploadUrl && values.file !== undefined) {
            // @ts-ignore
            await handleFileUpload(response.fileUploadUrl, values.file);
          }
        }
        enqueueSnackbar(`Results have been added for ${competitionId}`, { variant: 'success' });
        onClose();
      }
    },
    validationSchema,
    initialValues: {
      fileName: '',
      file: undefined,
      url: '',
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={'Upload Results'}
      primaryAction={handleSubmit}
      primaryActionText={'Upload Results'}
      primaryActionColor="primary"
      secondaryAction={onClose}
      secondaryActionText="Close"
      actionDisabled={isSubmitting}>
      <Typography>
        Uploading a new results file will overwrite any existing file. At this stage, only one file can be saved for
        each event. Results can either be; a PDF stored in Queensland Running's results storage (S3), or a URL to
        externally hosted results.
      </Typography>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FileUpload
            initialFiles={[]}
            onChange={(files: FileList) => {
              setFieldValue('file', files[0]);
              setFieldValue('fileName', suggestedName(competitionId));
              return files;
            }}
            onDelete={(file: any) => {
              setFieldValue('file', []);
              return file;
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!!(errors.fileName && touched.fileName)}
            label="File name"
            name="fileName"
            value={values.fileName}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={suggestedName(competitionId)}
            helperText={
              (errors.fileName && touched.fileName && errors.fileName) ||
              `The download url will be: <S3_BUCKET>/${competitionId.substr(0, 4)}/${values.fileName || '<FILENAME>'}`
            }
            disabled={false}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography>
            <b>OR</b>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            error={!!(errors.url && touched.url)}
            label="URL to results"
            name="url"
            value={values.url}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              (errors.url && touched.url && errors.url) ||
              'Link to results hosted by a third-party, such as https://www.webscorer.com/qldrun'
            }
            disabled={false}
            fullWidth
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export const UploadCompetitionResultsModal = enhance(UploadCompetitionResultsModalView);
