import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Form, FormikProps, withFormik } from 'formik';
import { Event as EventType } from '@generated/schema';
import { object } from 'yup';
import { compose } from 'recompose';
import {
  DeleteResultsProps as DeleteResultsMutationProps,
  withDeleteResults as withDeleteResultsMutation,
} from './mutations.generated';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { ApolloError } from '@apollo/client';
import { InferType } from 'prop-types';
import { Button } from '@queensland-running/qr-components';

const validationSchema = object().shape({});
type FormValues = InferType<typeof validationSchema>;

type DeleteResultsModalViewProps = DeleteResultsProps &
  FormikProps<FormValues> & {
    event: EventType;
  };

type DeleteResultsProps = { eventId: string; open: boolean; onClose: () => void };

const enhance = compose<DeleteResultsModalViewProps, DeleteResultsProps>(
  withSnackbar,
  withDeleteResultsMutation<DeleteResultsMutationProps & WithSnackbarProps & DeleteResultsProps>({
    alias: 'withDeleteResultsMutation',
    name: 'deleteResults',
    options: ({ enqueueSnackbar, closeSnackbar, eventId }) => ({
      onCompleted: () =>
        enqueueSnackbar(`Results have been successfully removed for ${eventId}`, { variant: 'success' }),
      onError: (error: ApolloError) =>
        enqueueSnackbar(error.message, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <Button
              title='Dismiss'
              onClick={() => {
                closeSnackbar(key);
              }}/>
          ),
        }),
    }),
  }),
  withFormik<{ deleteResults: any } & DeleteResultsMutationProps & DeleteResultsProps & WithSnackbarProps, FormValues>({
    handleSubmit: async (
      values,
      { setStatus, props: { deleteResults, onClose, eventId, enqueueSnackbar, closeSnackbar } },
    ) => {
      setStatus(null);
      await deleteResults({
        variables: {
          input: {
            eventId,
          },
        },
      }).catch((e: Error) => {
        enqueueSnackbar(e.message, {
          variant: 'error',
          persist: true,
          action: (key) => <Button onClick={() => closeSnackbar(key)} title='Dismiss' />,
        });
      });
      onClose();
    },
    mapPropsToValues: (): FormValues => {
      return {};
    },
    validationSchema,
  }),
  React.memo,
);

const DeleteResultsModalView = ({ isSubmitting, onClose, open }: DeleteResultsModalViewProps) => (
  <Dialog
    onClose={onClose}
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <Form>
      <DialogTitle id="alert-dialog-title">{'Delete Results'}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        <Typography>Are you sure you want to delete these results?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} title='No'/>
        <Button color="danger" type="submit" disabled={isSubmitting} title='Yes, Delete Results' />
      </DialogActions>
    </Form>
  </Dialog>
);

export const DeleteResultsModal = enhance(DeleteResultsModalView);
