import React from 'react';
import {Button, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, Typography,} from '@material-ui/core';
import {useFormik} from 'formik';
import {Modal} from '@queensland-running/qr-components';
import {object, string} from 'yup';
import {compose} from 'recompose';
import {useUpdateRockettRelaysFessMutation} from './mutations.generated';
import {useSnackbar} from 'notistack';
import {ApolloError} from '@apollo/client';
import {RockettRelays} from '@generated/schema';

const toBffFee = (fee: string): number => Math.round(parseFloat(fee) * 100);

const fee = string()
  .matches(/^\d+(\.\d{2})?$/, 'Fee should be a positive amount matching either 0 or 0.00')
  .required('Fee is a required field');

const validationSchema = object().shape({
  oneHourRelayWeeklyRunnerFee: fee,
  oneHourRelayNonWeeklyRunnerFee: fee,
  twoHourRelayWeeklyRunnerFee: fee,
  twoHourRelayNonWeeklyRunnerFee: fee,
});

type UpdateRockettRelaysFeesModalProps = { competition: RockettRelays; open: boolean; onClose: () => void };

const enhance = compose<UpdateRockettRelaysFeesModalProps, UpdateRockettRelaysFeesModalProps>(React.memo);

const UpdateRockettRelaysFeesModalView = ({competition, onClose, open}: UpdateRockettRelaysFeesModalProps) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [updateFees] = useUpdateRockettRelaysFessMutation({
    onError: (error: ApolloError) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        persist: true,
        action: (key) => (
          <Button
            onClick={() => {
              closeSnackbar(key);
            }}>
            Dismiss
          </Button>
        ),
      });
    },
  });

  const {errors, values, handleChange, handleBlur, isSubmitting, handleSubmit} = useFormik({
    onSubmit: async (values) => {
      const result = await updateFees({
        variables: {
          input: {
            competitionId: competition.id,
            ONE_HOUR_RELAY: {
              weeklyCompetitor: toBffFee(values.oneHourRelayWeeklyRunnerFee),
              nonWeeklyCompetitor: toBffFee(values.oneHourRelayNonWeeklyRunnerFee),
            },
            TWO_HOUR_RELAY: {
              weeklyCompetitor: toBffFee(values.twoHourRelayWeeklyRunnerFee),
              nonWeeklyCompetitor: toBffFee(values.twoHourRelayNonWeeklyRunnerFee),
            },
          },
        },
      });

      if (result.data) {
        enqueueSnackbar(`Fees have been updated for ${competition.agenda}`, {variant: 'success'});
        onClose();
      }
    },
    validationSchema,
    initialValues: {
      oneHourRelayWeeklyRunnerFee: competition.fees
        ? (competition.fees['ONE_HOUR_RELAY'].weeklyCompetitor / 100).toFixed(2).toString()
        : '0.00',
      oneHourRelayNonWeeklyRunnerFee: competition.fees
        ? (competition.fees['ONE_HOUR_RELAY'].nonWeeklyCompetitor / 100).toFixed(2).toString()
        : '0.00',
      twoHourRelayWeeklyRunnerFee: competition.fees
        ? (competition.fees['TWO_HOUR_RELAY'].weeklyCompetitor / 100).toFixed(2).toString()
        : '0.00',
      twoHourRelayNonWeeklyRunnerFee: competition.fees
        ? (competition.fees['TWO_HOUR_RELAY'].nonWeeklyCompetitor / 100).toFixed(2).toString()
        : '0.00',
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={'Update Fees'}
      primaryAction={handleSubmit}
      primaryActionText={'Update'}
      primaryActionColor="primary"
      secondaryAction={onClose}
      secondaryActionText="Close"
      actionDisabled={isSubmitting}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">1 Hour Relay</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!errors.oneHourRelayWeeklyRunnerFee}>
            <InputLabel htmlFor="oneHourRelayWeeklyRunnerFee">Weekly Competitors Fee</InputLabel>
            <Input
              id="oneHourRelayWeeklyRunnerFee"
              value={values.oneHourRelayWeeklyRunnerFee}
              onChange={handleChange}
              onBlur={handleBlur}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormHelperText>{errors.oneHourRelayWeeklyRunnerFee}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!errors.oneHourRelayNonWeeklyRunnerFee}>
            <InputLabel htmlFor="oneHourRelayNonWeeklyRunnerFee">Other Competitors Fee</InputLabel>
            <Input
              id="oneHourRelayNonWeeklyRunnerFee"
              value={values.oneHourRelayNonWeeklyRunnerFee}
              onChange={handleChange}
              onBlur={handleBlur}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormHelperText>{errors.oneHourRelayNonWeeklyRunnerFee}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">2 Hour Relay</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!errors.twoHourRelayWeeklyRunnerFee}>
            <InputLabel htmlFor="twoHourRelayWeeklyRunnerFee">Weekly Competitors Fee</InputLabel>
            <Input
              id="twoHourRelayWeeklyRunnerFee"
              value={values.twoHourRelayWeeklyRunnerFee}
              onChange={handleChange}
              onBlur={handleBlur}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormHelperText>{errors.twoHourRelayWeeklyRunnerFee}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!errors.twoHourRelayNonWeeklyRunnerFee}>
            <InputLabel htmlFor="twoHourRelayNonWeeklyRunnerFee">Other Competitors Fee</InputLabel>
            <Input
              id="twoHourRelayNonWeeklyRunnerFee"
              value={values.twoHourRelayNonWeeklyRunnerFee}
              onChange={handleChange}
              onBlur={handleBlur}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
            <FormHelperText>{errors.twoHourRelayNonWeeklyRunnerFee}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Modal>
  );
};

export const UpdateRockettRelaysFeesModal = enhance(UpdateRockettRelaysFeesModalView);
