import { Park } from '@generated/schema';
import * as env from "./environment";

export enum RouteKeys {
  appBase = 'appBase',
  accounts = 'accounts',
  runners = 'runners',
  config = 'config',
  events = 'events',
  venues = 'venues',
  venue = 'venue',
  administrators = 'administrators',
  event = 'event',
  competition = 'competition',
  calendar = 'calendar',
  profile = 'profile',
  registration = 'registration',
  statistics = 'statistics',
}

export const Routes = {
  [RouteKeys.appBase]: '/',
  [RouteKeys.accounts]: '/accounts',
  [RouteKeys.runners]: '/runners',
  [RouteKeys.config]: '/config',
  [RouteKeys.events]: '/events',
  [RouteKeys.event]: '/event',
  [RouteKeys.competition]: '/competition',
  [RouteKeys.calendar]: '/calendar',
  [RouteKeys.profile]: '/profile',
  [RouteKeys.venues]: '/venues',
  [RouteKeys.venue]: '/venues/:name',
  [RouteKeys.registration]: '/registration',
  [RouteKeys.statistics]: '/statistics',
  [RouteKeys.administrators]: '/administrators'
};

export const RouteLabels = {
  [RouteKeys.appBase]: 'Home',
  [RouteKeys.accounts]: 'Accounts',
  [RouteKeys.config]: 'Config',
  [RouteKeys.runners]: 'Runners',
  [RouteKeys.events]: 'Events',
  [RouteKeys.event]: 'Event',
  [RouteKeys.competition]: 'Competition',
  [RouteKeys.calendar]: 'Calendar',
  [RouteKeys.profile]: 'Profile',
  [RouteKeys.venues]: 'Venues',
  [RouteKeys.venue]: 'Venue',
  [RouteKeys.registration]: 'Registration',
  [RouteKeys.administrators]: 'Administrators',
  [RouteKeys.statistics]: 'Statistics',
};

export enum ExternalRouteKeys {
  aws = 'aws',
  github = 'github',
  seq = 'seq',
  sentry = 'sentry',
  sentryAdmin = 'sentryAdmin',
  storybook = 'storybook',
}

export const ExternalRoutes = {
  [ExternalRouteKeys.aws]: `https://queenslandrunning.signin.aws.amazon.com/console`,
  [ExternalRouteKeys.github]: `https://github.com/queensland-running/website`,
  // [ExternalRouteKeys.seq]: env.seq.url,
  [ExternalRouteKeys.sentry]: env.sentry.projects.webClientUrl,
  [ExternalRouteKeys.sentryAdmin]: env.sentry.projects.adminWebClientUrl,
  [ExternalRouteKeys.storybook]: ``,
};

export const ExternalRouteLabels = {
  [ExternalRouteKeys.aws]: 'AWS',
  [ExternalRouteKeys.github]: 'Github',
  [ExternalRouteKeys.seq]: 'Seq',
  [ExternalRouteKeys.sentry]: 'Sentry',
  [ExternalRouteKeys.sentryAdmin]: 'Sentry (Admin)',
  [ExternalRouteKeys.storybook]: 'Storybook',
};

export const mapRoute = (venue: Park['url']) => Routes.venues + '/' + venue;
