/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchAdministratorQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchAdministratorQuery = { readonly __typename?: 'Query' } & {
  readonly administrator?: Types.Maybe<
    { readonly __typename?: 'Administrator' } & Pick<
      Types.Administrator,
      | 'id'
      | 'nickname'
      | 'picture'
      | 'name'
      | 'email'
      | 'lastLogin'
      | 'createdAt'
      | 'updatedAt'
      | 'emailVerified'
      | 'firstName'
      | 'lastName'
    > & {
        readonly roles?: Types.Maybe<
          ReadonlyArray<{ readonly __typename?: 'Role' } & Pick<Types.Role, 'name' | 'description'>>
        >;
      }
  >;
};

export const FetchAdministratorDocument = gql`
  query fetchAdministrator {
    administrator {
      id
      nickname
      picture
      name
      email
      lastLogin
      createdAt
      updatedAt
      emailVerified
      firstName
      lastName
      roles {
        name
        description
      }
    }
  }
`;
export type FetchAdministratorComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchAdministratorQuery, FetchAdministratorQueryVariables>,
  'query'
>;

export const FetchAdministratorComponent = (props: FetchAdministratorComponentProps) => (
  <ApolloReactComponents.Query<FetchAdministratorQuery, FetchAdministratorQueryVariables>
    query={FetchAdministratorDocument}
    {...props}
  />
);

export type FetchAdministratorProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchAdministratorQuery, FetchAdministratorQueryVariables>;
} &
  TChildProps;
export function withFetchAdministrator<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchAdministratorQuery,
    FetchAdministratorQueryVariables,
    FetchAdministratorProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchAdministratorQuery,
    FetchAdministratorQueryVariables,
    FetchAdministratorProps<TChildProps, TDataName>
  >(FetchAdministratorDocument, {
    alias: 'fetchAdministrator',
    ...operationOptions,
  });
}

/**
 * __useFetchAdministratorQuery__
 *
 * To run a query within a React component, call `useFetchAdministratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAdministratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAdministratorQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAdministratorQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAdministratorQuery, FetchAdministratorQueryVariables>,
) {
  return Apollo.useQuery<FetchAdministratorQuery, FetchAdministratorQueryVariables>(
    FetchAdministratorDocument,
    baseOptions,
  );
}
export function useFetchAdministratorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAdministratorQuery, FetchAdministratorQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchAdministratorQuery, FetchAdministratorQueryVariables>(
    FetchAdministratorDocument,
    baseOptions,
  );
}
export type FetchAdministratorQueryHookResult = ReturnType<typeof useFetchAdministratorQuery>;
export type FetchAdministratorLazyQueryHookResult = ReturnType<typeof useFetchAdministratorLazyQuery>;
export type FetchAdministratorQueryResult = Apollo.QueryResult<
  FetchAdministratorQuery,
  FetchAdministratorQueryVariables
>;
