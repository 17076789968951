/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchRunnersQueryVariables = Types.Exact<{
  input: Types.FilterRunnersInput;
}>;

export type FetchRunnersQuery = { readonly __typename?: 'Query' } & {
  readonly adminRunners?: Types.Maybe<
    { readonly __typename?: 'RunnersQueryResponse' } & {
      readonly data: ReadonlyArray<
        { readonly __typename?: 'Runner' } & Pick<
          Types.Runner,
          | 'id'
          | 'bibNumber'
          | 'firstName'
          | 'lastName'
          | 'dateOfBirth'
          | 'club'
          | 'gender'
          | 'parentId'
          | 'status'
          | 'membership'
          | 'memberships'
          | 'hidden'
        >
      >;
      readonly accounts?: Types.Maybe<
        ReadonlyArray<
          Types.Maybe<
            { readonly __typename?: 'UserAccount' } & Pick<
              Types.UserAccount,
              'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'postalAddress' | 'postcode' | 'suburb'
            >
          >
        >
      >;
    }
  >;
};

export const FetchRunnersDocument = gql`
  query fetchRunners($input: FilterRunnersInput!) {
    adminRunners(input: $input) {
      data {
        id
        bibNumber
        firstName
        lastName
        dateOfBirth
        club
        gender
        parentId
        status
        membership
        memberships
        hidden
      }
      accounts {
        id
        firstName
        lastName
        email
        phoneNumber
        postalAddress
        postcode
        suburb
      }
    }
  }
`;
export type FetchRunnersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchRunnersQuery, FetchRunnersQueryVariables>,
  'query'
> &
  ({ variables: FetchRunnersQueryVariables; skip?: boolean } | { skip: boolean });

export const FetchRunnersComponent = (props: FetchRunnersComponentProps) => (
  <ApolloReactComponents.Query<FetchRunnersQuery, FetchRunnersQueryVariables> query={FetchRunnersDocument} {...props} />
);

export type FetchRunnersProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchRunnersQuery, FetchRunnersQueryVariables>;
} &
  TChildProps;
export function withFetchRunners<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchRunnersQuery,
    FetchRunnersQueryVariables,
    FetchRunnersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchRunnersQuery,
    FetchRunnersQueryVariables,
    FetchRunnersProps<TChildProps, TDataName>
  >(FetchRunnersDocument, {
    alias: 'fetchRunners',
    ...operationOptions,
  });
}

/**
 * __useFetchRunnersQuery__
 *
 * To run a query within a React component, call `useFetchRunnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRunnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRunnersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchRunnersQuery(
  baseOptions: Apollo.QueryHookOptions<FetchRunnersQuery, FetchRunnersQueryVariables>,
) {
  return Apollo.useQuery<FetchRunnersQuery, FetchRunnersQueryVariables>(FetchRunnersDocument, baseOptions);
}
export function useFetchRunnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchRunnersQuery, FetchRunnersQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchRunnersQuery, FetchRunnersQueryVariables>(FetchRunnersDocument, baseOptions);
}
export type FetchRunnersQueryHookResult = ReturnType<typeof useFetchRunnersQuery>;
export type FetchRunnersLazyQueryHookResult = ReturnType<typeof useFetchRunnersLazyQuery>;
export type FetchRunnersQueryResult = Apollo.QueryResult<FetchRunnersQuery, FetchRunnersQueryVariables>;
