import React from 'react';
import { compose } from 'recompose';
import { Grid } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useFetchAccountsQuery } from './queries.generated';
import { RouteKeys, RouteLabels, Routes } from '@constants';
import { UserAccountTable } from '@components/user-account-table';
import { ErrorComponent, LoadingComponent, PageTitle } from '@queensland-running/qr-components';
import { UserAccount } from '@generated/schema';

type UserAccountsProps = {};
type UserAccountsViewProps = UserAccountsProps & RouteComponentProps;

const enhance = compose<UserAccountsViewProps, UserAccountsProps>(withRouter, React.memo);

const UserAccountsView = ({ history }: UserAccountsViewProps) => {
  const { data, loading, error } = useFetchAccountsQuery();

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent title={'Error'} message={'Something went wrong'} />;
  }

  const accounts = (data && (data.accounts as UserAccount[])) || [];

  return (
    <>
      <PageTitle title={RouteLabels[RouteKeys.accounts]}/>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UserAccountTable
            accounts={accounts}
            onEditAccount={() => {}}
            onNavigateToRunners={(id: string) =>
              history.push({
                pathname: Routes[RouteKeys.runners],
                search: `?pId=${id}`,
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export const Accounts = enhance(UserAccountsView);
