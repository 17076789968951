import { noop } from 'lodash/fp';
import React from 'react';
import {Typography} from "@material-ui/core";

export type ErrorInfo = {
    componentStack: string;
};

export type ErrorBoundaryProps = {
    fallbackComponent?: React.ComponentType;
    onError?: (error: Error, errorInfo: ErrorInfo) => void;
};

type ErrorBoundaryState = {
    error?: Error;
};

export class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
    > {
    static getDerivedStateFromError = (error: Error) => ({ error });

    componentDidCatch = (error: Error, errorInfo: ErrorInfo) =>
        (this.props.onError ? this.props.onError : noop)(error, errorInfo);

    render = () =>
        this.state && this.state.error ? (
            this.props.fallbackComponent ? (
                React.createElement(this.props.fallbackComponent)
            ) : (
                <div className="flex-column w-100">
                    <div className="flex-center">
                        <div className="text-center w-100">
                            <Typography variant="h1" gutterBottom>Oops! Something went wrong.</Typography>
                            <Typography variant="body1">Please try reloading the page.</Typography>
                        </div>
                    </div>
                </div>
            )
        ) : (
            this.props.children
        );
}
