import { RouteKeys, RouteLabels, Routes } from '../@constants';
import { flow, map } from 'lodash/fp';

export const toRoutes = (routes: RouteKeys[], year?: string) =>
  flow(
    map((key: RouteKeys) => ({
      label: RouteLabels[key],
      route: Routes[key] && Routes[key].replace(':year', year || ''),
    })),
  )(routes);
